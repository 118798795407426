import { AfterContentChecked, AfterContentInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { RxFormGroup } from '@rxweb/reactive-form-validators';
import { Observable, Subject, Subscription } from 'rxjs';
import { first, debounceTime } from 'rxjs/operators';
import { DynamicFormsService } from '../../dynamic-forms.service';
import { DynControlBase } from '../dyn-controls-base';

@Component({
  selector: 'app-dyn-dropdown',
  templateUrl: './dyn-dropdown.component.html',
  styleUrls: ['./dyn-dropdown.component.scss', '../dyn-controls.scss'],
})
export class DynDropdownComponent extends DynControlBase implements AfterContentChecked, OnDestroy, OnChanges, AfterContentInit  {
  @Input() list: Observable<any> = new Observable<any>();  //tous les éléments
  @Input() listSelectedItems: Observable<any> = new Observable<any>(); //Éléments pour le bind, lorsque la list n'est pas utilisée

  @ViewChild('searchInput') searchInput!: ElementRef;
  
  public items: any[] = []; 
  public filteredItems: any[] = [];
  public filterCtrl = new FormControl();
  public isLoading = false;
  public isOpen = false;
  public selectedItems: any[] = [];

  private isSearchBackendEnabled = false;
  private subscriptions = new Subscription();
  private previousValue: any;

  constructor(
    public override dynamicFormsService: DynamicFormsService,
    private changeDetectorRef: ChangeDetectorRef
    ){
    super(dynamicFormsService);
  }

  ngAfterContentInit(): void {
    if (this.control?.search) {
      this.isSearchBackendEnabled = this.control?.search?.backendFilterContextFuncName && 
          this.context[this.control?.search?.backendFilterContextFuncName] ? true : false;
      this.configSearch();

      //Désactiver la recherche défaut du mat-select
      setTimeout(() => {
        this.searchInput?.nativeElement?.addEventListener('keydown', (e: any) => {
          this.disableDefaultSearch(e);
        }, false);
      }, 0);
    }
  }
  ngAfterContentChecked(): void { //for new Angular version 
    this.afterContentChecked();
    if (this.form && this.control && this.form.get(this.control.keyName)) {
      const formControl = this.form.get(this.control.keyName) as RxFormGroup;
      if (this.isReadOnly()) {
        if (!formControl.disabled) {
          formControl.disable();
        }
      } else {
        if (formControl.disabled) {
          formControl.enable();
        }
      }
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['listSelectedItems']?.currentValue) {
      this.selectedItems = [];
      this.loadSelectedItems(changes['listSelectedItems'].currentValue);
    } else if (changes && changes['list']?.currentValue) {
      this.selectedItems = [];
      this.loadAllItems(changes['list'].currentValue);
    }
  }

  getValue(item: any): any {
    return item ? (this.control.forceValueToGetObject ? item : item[this.control.fieldNameValue] || item.id || item) : null
  }
  getDescription(item: any): any {
    if (this.control.fieldNameTextFunction) {
      return item ? this.control.fieldNameTextFunction(item) : '';
    } else {
      return item ? (item[this.control.fieldNameText] || item.name || item) : ''  
    }
  }
  
  newItem() {
    const dirty = this.form.get(this.control.keyName)._dirty;
    this.form.get(this.control.keyName).patchValue(this.previousValue); 
    if(!dirty)this.form.controls[this.control.keyName].markAsPristine();
    setTimeout(() => this.callCustomFunction(this.control?.actionNewName), 10);
  }

  selectionChange(fieldValue: any) {
    if (!fieldValue) {
      return;
    } else if (fieldValue.length === 0) {
      this.selectedItems = [];
    }
    if (this.control.objectFieldName && !this.control.isMultiple) {
      const item = this.items.find(x => this.getProp(x) === fieldValue);
      if (item) {
        const formItem = this.form.get(this.control.objectFieldName) as RxFormGroup;
        if (formItem) {
          formItem.patchValue(item);
        }
      }
    }
    if (this.isSearchBackendEnabled && this.control.isMultiple) {
      // Ajouter les éléments sélectionnés à la liste de tous les éléments. Les filtres backend modifient la liste de tous les éléments   
      const selectedItems = this.selectedItems.filter(x => fieldValue.includes(this.getProp(x)));
      const difItems = this.getSourceItemsAreNotInDestination(selectedItems, this.items)
      this.items.push(...difItems);
    }
    if(this.control.isMultiple) {
      this.setSelectedItems(fieldValue);
      if (this.selectedItems.length === 0) {
        this.filteredItems = [...this.items];
      }
      else {
        if (this.control?.search) {
          this.setFilteredItems(this.filteredItems.length > 0 ? this.filteredItems : this.items);
        } else {
          this.setFilteredItems(this.items);
        }
        if (this.isSearchBackendEnabled) {
          this.items = [...this.filteredItems];
        }
      }
    } else if (this.control.search) {
      this.filteredItems = [...this.items];
    }
  }

  openChanged(isOpen: boolean, value: any) {
    this.previousValue = value;
    if(this.control.fieldNamePreviousValue && isOpen){
      this.form.get(this.control.fieldNamePreviousValue).patchValue(value);
    }
    if (this.control.loadItemsOnlyWhenClick && !this.isSearchBackendEnabled) {
      this.isOpen = isOpen;
      this.isLoading = isOpen
      if (isOpen) {
        this.loadAllItems(this.list);
      }
    }
    if (isOpen && this.searchInput && !this.isLoading)
      this.setSearchInputFocus();
    else {
      if (this.isSearchBackendEnabled && this.control.isMultiple) {
        this.filteredItems = [];
        this.items = [];
      }
      setTimeout(() => this.filterCtrl.patchValue(null), 10);
    }
    if (this.control.isMultiple && !isOpen && this.control?.afterMultiSelectionContextFuncName) {
      this.callCustomFunction(this.control.afterMultiSelectionContextFuncName, this.selectedItems);
      if (this.control?.cleanValuesAfterMultiSelection) {
        this.items = [];
        this.filteredItems = [];
        this.selectedItems = [];
        const formGroupControl = this.form.get(this.control.keyName) as RxFormGroup;
        formGroupControl.patchValue([]);
      }
      console.log(this.form.value)
    }
  }

  callDynamicRequest(value: any){
    const obsQuery = this.context[this.control?.search?.backendFilterContextFuncName](value);
    this.loadAllItems(obsQuery);
  }

  private disableDefaultSearch(event: any) {
    if (event && (event.target as any).nodeName === 'INPUT') {
      event.stopImmediatePropagation();
    }
  }

  private getSourceItemsAreNotInDestination(sourceItems: any, destinationItems: any[]): any[] {
    if (this.control.isMultiple) 
      return sourceItems.filter((x: any) => destinationItems.findIndex(y => this.getProp(x) === this.getProp(y)) === -1)
    else 
      return sourceItems.filter((x: any) => this.getProp(x) !== this.getProp(destinationItems[0]));
  }
  private getProp(item: any): any {
    return item[this.control.fieldNameValue] || item.id || item;
  }

  private setSelectedItems(value: any) {
    if (!value || (value && value.length === 0)) {
      this.selectedItems = [];
    }
    this.selectedItems = this.control.isMultiple ? this.items.filter(x => value?.includes(this.getProp(x))) : 
      this.items.filter(x => this.getProp(x) === value);
  }
  private setFilteredItems(items: any[]) {
    this.filteredItems =  items.filter(x => !this.selectedItems.find(y => this.getProp(y) === this.getProp(x)));
  }

  private loadSelectedItems(obs: Observable<any>){
    this.subscriptions.add(obs.pipe(first()).subscribe((item: any) => {
      if (item) {
        const items  = Array.isArray(item) ? item : [item];
        this.items = [...items];
        this.filteredItems = [...items];
        const formGroupControl = this.form.get(this.control.keyName) as RxFormGroup;
        if (this.control.isMultiple) {
          const values =  items.map(x => this.getValue(x)) ;
          formGroupControl.patchValue(values);
          this.setSelectedItems(this.form.get(this.control.keyName)?.value); 
          this.setFilteredItems(this.items);
        } else {
          const value = this.getValue(items[0]);
          formGroupControl.patchValue(value);
        }
      }
    }));
  }
  private loadAllItems(obs: Observable<any>){
    this.subscriptions.add(obs.pipe(first()).subscribe((items: any) => {
      if (items) {
        this.items = [...items];
        this.filteredItems = [...items];
        if (this.control.isMultiple){
          if (!this.isSearchBackendEnabled) {
            this.setSelectedItems(this.form.get(this.control.keyName)?.value); 
          }
          this.setFilteredItems(this.items);
        }
        if (this.control.forceChangeDetector){
          this.changeDetectorRef.detectChanges();
        }
      }
      setTimeout(() => {
        this.isLoading = false;
        if (this.control.loadItemsOnlyWhenClick && this.searchInput)
          this.setSearchInputFocus();
      }, 1000);
    }));
  }

  private setSearchInputFocus() {
    setTimeout(() => this.searchInput.nativeElement.focus(), 10);
  }

  private configSearch(){
    this.subscriptions.add(this.filterCtrl.valueChanges.pipe(
      debounceTime(this.control.search?.debounceTime || 300))
      .subscribe(value => {
        const minCharacter = this.control?.search?.minCharactersStartSearch || 3;
        if (value && (!minCharacter || (minCharacter && value.toString().length >= minCharacter))) {
          if (this.isSearchBackendEnabled) { 
            //backend Search
            this.callDynamicRequest(value);
          } else { 
            //Frontend search
              const items = this.control.search?.filterFunction ? 
                this.control.search?.filterFunction(value, this.items) : this.filter(value);
              this.setFilteredItems(items);
          }
        } else {
          this.setFilteredItems(this.items);
        }
      }));
  }

  private filter(value: string): any[] {
    const filterValue = value.toLowerCase().trim().replace(/ +(?= )/g,'');
    return this.items.filter((item: any) => this.control?.fieldNameValue ? 
      this.cleanValue(item[this.control?.fieldNameValue]).includes(this.cleanValue(filterValue)) || 
      this.cleanValue(item[this.control?.fieldNameText]).includes(this.cleanValue(filterValue)) :
      this.cleanValue(item).includes(this.cleanValue(filterValue)));
  }
  private cleanValue(value: any) {
    return typeof(value) !== 'object' ? value.toString().toLowerCase().trim().replace(/ +(?= )/g,'') : '';
  }


  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
