<div class="back">
    <div class=outer>
        <div class="middle">
            <div class="inner">
                <div class="logo">
                    <img src="assets/img/logo.png">
                </div>
                <div>
                    <h3 class="appName">TinERP <mat-icon>widgets</mat-icon></h3>
                </div>
                <div class="action" fxLayoutAlign="center center">
                    <mat-spinner *ngIf="!authService.isUnauthorizedUser" diameter="80" color="primary"></mat-spinner>
                    <button *ngIf="authService.isUnauthorizedUser" mat-raised-button (click)="login()">{{"global.connect" | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
