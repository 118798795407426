import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BusService } from '../../services/bus.service';
import { MessageAction, ResponseConfirmation } from '../../global';
import { DeviceDetectorService } from 'ngx-device-detector';

export interface DialogData {
  title: '';
  message: '';
}

@Component({
  selector: 'app-tin-dialog',
  templateUrl: './tin-dialog.component.html',
  styleUrls: ['./tin-dialog.component.scss']
})

export class TinDialogComponent  {

  isMobile = false;
  textareaValue = '';

  constructor(
    public dialogRef: MatDialogRef<TinDialogComponent>,
    private deviceService: DeviceDetectorService,
    private serviceBus: BusService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.isMobile = this.deviceService.isMobile();
    }

  no(): void {
    this.dialogRef.close({response: ResponseConfirmation.No});
  }

  yes(): void {
    this.dialogRef.close({response: ResponseConfirmation.Yes, item:{text : this.textareaValue}});
  }

  ok() {
    this.dialogRef.close({response: ResponseConfirmation.Ok, item:{text : this.textareaValue}});
  }

  saveAndSend(){
    this.dialogRef.close({response: ResponseConfirmation.SaveAndSend, item:{text : this.textareaValue}});
  }

  save() {
    this.serviceBus.sendMessage(MessageAction.HeaderDetailActionSave);
    this.serviceBus.hasUnsavedChanges = false;
    setTimeout(() => {
      this.dialogRef.close({response: ResponseConfirmation.Yes, item:{text : this.textareaValue}});
    }, 0);
   }
   cancel(){
    this.serviceBus.sendMessage(MessageAction.HeaderDetailActionCancel);
    this.serviceBus.hasUnsavedChanges = false;
    setTimeout(() => {
      this.dialogRef.close({response: ResponseConfirmation.Yes});
    }, 0);
   }

   textareaChange(e:any){
    this.textareaValue = e.target.value.trimStart();
   }
}
