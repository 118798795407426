import { TinSpinnerComponent } from './tin-spinner.component';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, NEVER } from 'rxjs';
import { finalize, share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
  })
export class TinSpinnerService {
    public isLoading = new BehaviorSubject<boolean>(false);
    private overlayRef: OverlayRef | undefined = undefined;
    private timeBuffer: any;

  constructor(private overlay: Overlay) { }

  public readonly spinner$ = defer(() => {
    this.show();
    return NEVER.pipe(
      finalize(() => {
        this.hide();
      })
    );
  }).pipe(share());

  public show(): void {
    // Wait a little bit before showing the loading spinner
    // If we show the spinner for short operation it gets annoying for the user
    this.timeBuffer = setTimeout(() =>
    {
      Promise.resolve(null).then(() => {
        this.overlayRef = this.overlay.create({
          positionStrategy: this.overlay
            .position()
            .global()
            .centerHorizontally()
            .centerVertically(),
          hasBackdrop: true,
        });
        this.overlayRef.attach(new ComponentPortal(TinSpinnerComponent));
      });
    },
    300);
  }

  public hide(): void {
    // Cancel the show action if it hasn't already been done
    clearTimeout(this.timeBuffer);
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    this.overlayRef = undefined;
  }
}
