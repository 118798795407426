import {Injector} from '@angular/core';


export let AppInjector: Injector;

export function setAppInjector(injector: Injector): void {
    if (AppInjector) {
        // Ne devrait pas arriver
        console.error('AppInjector était déjà défini');
    }
    else {
        AppInjector = injector;
    }
}
