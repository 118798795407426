import { PermissionsGroupAdmin, PermissionsGroupClient, PermissionsGroupEmploye, PermissionsModuleAdmin, PermissionsModuleEmploye } from 'src/app/auth/permissions';

export interface BadgeItem {
    type: string;
    value: string;
}
export interface Separator {
    name: string;
    type?: string;
}
export interface SubChildren {
    state: string;
    name: string;
    type?: string;
}
export interface ChildrenItems {
    state: string;
    name: string;
    type?: string;
    child?: SubChildren[];
}

export interface Menu {
    state: string;
    name: string;
    type: string;
    icon: string;
    badge?: BadgeItem[];
    separator?: Separator[];
    children?: ChildrenItems[];
    permissions: string[]; // change to required
}

export const  ClientMenuItems: Menu[] = [
  // {
  //   state: 'dashboard',
  //   name: 'menu.dashboard',
  //   type: 'link',
  //   icon: 'av_timer',
  //   permissions: PermissionsGroupClient.ClientTableauDeBord
  // },
  {
    state: '',
    name: 'menu.transport',
    type: 'separator',
    icon: '',
    permissions: PermissionsGroupClient.ClientFacture
    .concat(PermissionsGroupClient.ClientDemandeCueillette)
    .concat(PermissionsGroupClient.ClientMesClient )
  },
  {
    state: 'facture',
    name: 'menu.facture',
    type: 'link',
    icon: 'request_quote',
    permissions: PermissionsGroupClient.ClientFacture
  },
  {
    state: 'client-demande-cueillette',
    name: 'menu.demandeCueillette',
    type: 'link',
    icon: 'local_shipping',
    badge: [{type: 'primary', value:'new'}],
    permissions: PermissionsGroupClient.ClientDemandeCueillette 
  },
  {
    state: 'client-operation',
    name: 'menu.mesClients',
    type: 'link',
    icon: 'business',
    badge: [{type: 'primary', value:'new'}],
    permissions: PermissionsGroupClient.ClientMesClient 
  },
  {
    state: '',
    name: 'menu.warehousing',
    type: 'separator',
    icon: '',
    permissions: PermissionsGroupClient.ClientInventaire
  },
  {
    state: 'entreposage',
    name: 'menu.entreposage',
    type: 'link',
    icon: 'corporate_fare',
    permissions: PermissionsGroupClient.ClientInventaire
  },
  {
    state: '',
    name: 'menu.form',
    type: 'separator',
    icon: '',
    permissions: PermissionsGroupClient.ClientDemandeCueilletteFormulaire
  },
  {
    state: 'demande-cueillette-form',
    name: 'menu.demandeCueillette',
    type: 'link',
    icon: 'local_shipping',
    permissions: PermissionsGroupClient.ClientDemandeCueilletteFormulaire
  },
  {
    state: '',
    name: 'menu.settings',
    type: 'separator',
    icon: '',
    permissions: PermissionsGroupClient.ClientPreference.concat(PermissionsGroupClient.ClientMesInformations) 
  },
  {
    state: 'mes-informations',
    name: 'menu.myInformations',
    type: 'link',
    icon: 'contact_phone',
    badge: [{type: 'primary', value:'new'}],
    permissions: PermissionsGroupClient.ClientMesInformations 
  },
  {
    state: 'preference',
    name: 'menu.preferences',
    type: 'link',
    icon: 'settings',
    badge: [{type: 'primary', value:'new'}],
    permissions: PermissionsGroupClient.ClientPreference 
  },
  // {
  //   state:'',
  //   name: 'menu.cueilletteLivraison',
  //   type: 'separator',
  //   icon: '',
  //   permissions: PermissionsGroupClient.ClientPreference//TODO creer client demande-cueillette
  // },
  // {
  //   state: 'cueillette-livraison',
  //   name: 'menu.demandeCueillette',
  //   type: 'link',
  //   icon: 'local_shipping',
  //   permissions: PermissionsGroupClient.ClientPreference//TODO creer client demande-cueillette
  // },
];
export const  EmployeMenuItems: Menu[] = [
  {
    state: 'dashboard',
    name: 'menu.dashboard',
    type: 'link',
    icon: 'av_timer',
    permissions: PermissionsGroupEmploye.EmployeTableauDeBord
  },
  {
    state: '',
    name: 'menu.employe',
    type: 'separator',
    icon: '',
    permissions: PermissionsModuleEmploye.ModuleEmployeLire
  },
  {
    state: 'talon-paie',
    name: 'menu.talonPaie',
    type: 'link',
    icon: 'attach_money',
    permissions: PermissionsGroupEmploye.EmployeTalonPaie
  },
  {
    state: 'temps',
    name: 'menu.temps',
    type: 'link',
    icon: 'schedule',
    permissions: PermissionsGroupEmploye.EmployeHeuresTravaillees
  },
  {
    state: 'vacance-conge-flottant',
    name: 'menu.vacanceCongeFlottant',
    type: 'link',
    icon: 'local_hotel',
    permissions: PermissionsGroupEmploye.EmployeVacancesCongesSuivis
  },
  {
    state: 'journal',
    name: 'menu.journal',
    type: 'link',
    icon: 'assignment',
    permissions: PermissionsGroupEmploye.EmployeJournalDeBord
  },
  {
    state: 'comite',
    name: 'menu.comite',
    type: 'link',
    icon: 'group',
    permissions: PermissionsGroupEmploye.EmployeJournalDeComite
  },
  {
    state: 'document-employe',
    name: 'menu.documentEmploye',
    type: 'link',
    icon: 'library_books',
    permissions: PermissionsGroupEmploye.EmployeJournalDeBord
  },
  {
    state: 'formation',
    name: 'global.formation',
    type: 'link',
    icon: 'model_training',
    permissions: PermissionsGroupEmploye.EmployeFormationLire
  },
  {
    state: '',
    name: 'global.modules',
    type: 'separator',
    icon: '',
    permissions: PermissionsGroupEmploye.EmployeModule
  },
  {
    state: 'dispatch',
    name: 'menu.repartition',
    type: 'link',
    icon: 'send_time_extension',
    badge: [{type: 'primary', value:'beta'}],
    permissions: PermissionsGroupEmploye.EmployeRepartition
  },
  {
    state: 'cueillette-livraison',
    name: 'menu.cueilletteLivraison',
    type: 'link',
    icon: 'local_shipping',
    permissions: PermissionsGroupEmploye.EmployeDemandeCueillette
  },
  {
    state: '',
    name: 'menu.quickMenu',
    type: 'separator',
    icon: '',
    permissions: PermissionsGroupEmploye.EmployeAccesRapide
  },
  {
    state: 'cedules',
    name: 'menu.cedules',
    type: 'link',
    icon: 'person',
    permissions: PermissionsModuleEmploye.EmployeCeduleLire
  },
  {
    state: 'employes',
    name: 'menu.speedDial',
    type: 'link',
    icon: 'person',
    permissions: PermissionsModuleEmploye.EmployeSpeedDialLire
  },
  {
    state: '',
    name: 'global.management',
    type: 'separator',
    icon: '',
    permissions: PermissionsModuleAdmin.ModuleAdminOuGestionUtilisateur
  },
  {
    state: 'gestion-employe-utilisateur',
    name: 'global.employee',
    type: 'link',
    icon: 'account_circle',
    permissions: PermissionsGroupEmploye.EmployeGetionUserEmploye
  },
  {
    state: 'gestion-client-utilisateur',
    name: 'global.client',
    type: 'link',
    icon: 'account_circle',
    permissions: PermissionsGroupEmploye.EmployeGestionUserClient
  },
  {
    state: 'notification-gestion',
    name: 'global.notification',
    type: 'link',
    icon: 'alarm_add',
    badge: [{type: 'info', value:'dev'}],
    permissions: PermissionsGroupEmploye.EmployeNotification,
  },
  {
    state: 'admin',
    name: 'global.security',
    type: 'extLink',
    icon: 'manage_accounts',
    permissions: PermissionsModuleAdmin.ModuleAdminLire
  }
];

export const DemandeCueilletteMenuItems: Menu[] = [
  {
    state:'',
    name: 'menu.cueilletteLivraison',
    type: 'separator',
    icon: '',
    permissions: PermissionsGroupEmploye.EmployeDemandeCueillette
  },
  {
    state: 'demande-cueillette',
    name: 'menu.demandeCueillette',
    type: 'link',
    icon: 'local_shipping',
    permissions: PermissionsGroupEmploye.EmployeDemandeCueillette
  },
  {
    state: 'demande-cueillette-service',
    name: 'menu.requiredService',
    type: 'link',
    icon: 'fact_check',
    permissions: PermissionsGroupEmploye.EmployeServiceRequis 
  },
  {
    state: 'employe/dashboard',
    name: 'global.mainMenu',
    type: 'extLink',
    icon: 'arrow_back',
    permissions: PermissionsModuleEmploye.ModuleEmployeLire
  }
];

export const DispatchMenuItems: Menu[] = [
  {
    state: '',
    name: 'menu.repartition',
    type: 'separator',
    icon: '',
    permissions: PermissionsGroupEmploye.EmployeRepartition,
  },
  {
    state: 'pickup-management',
    name: 'global.actMgmt',
    type: 'link',
    icon: 'person_pin_circle',
    badge: [{type: 'primary', value:'beta'}],
    permissions: PermissionsGroupEmploye.EmployeRepartition
  },
  {
    state:'remorque-inventaire',
    name:'menu.remorqueInventaire',
    type:'link',
    icon:'rv_hookup',
    badge: [{type: 'primary', value:'beta'}],
    permissions: PermissionsGroupEmploye.EmployeRepartition
  },
  {
    state:'echange-remorque',
    name:'menu.echangeRemorque',
    type:'link',
    icon:'compare_arrows',
    badge: [{type: 'primary', value:'beta'}],
    permissions: PermissionsGroupEmploye.EmployeRepartition
  },
  {
    state:'rolland',
    name:'menu.rolland',
    type: 'link',
    icon:'factory',
    badge: [{type: 'primary', value:'beta'}],
    permissions: PermissionsGroupEmploye.EmployeRepartition
  },
  {
    state: 'livraison-dispatch',
    name:'global.delivery',
    type:'link',
    icon:'local_shipping',
    permissions: PermissionsGroupEmploye.EmployeRepartition
  },
  {
    state: 'territoire-repartition',
    name: 'global.territoire',
    type: 'link',
    icon: 'local_library',
    permissions: PermissionsGroupEmploye.EmployeTerritoire 
  },
  {
    state:'client-transport-installation',
    name:'menu.clientTransportInstallation',
    type:'link',
    icon:'add_home_work',
    permissions:PermissionsGroupEmploye.EmployeInstallationClient  
  },
  {
    state: 'employe/dashboard',
    name: 'global.mainMenu',
    type: 'extLink',
    icon: 'arrow_back',
    permissions: PermissionsModuleEmploye.ModuleEmployeLire
  },
];

export const FormationMenuItems: Menu[] = [
  {
    state: '',
    name: 'global.formation',
    type: 'separator',
    icon: '',
    permissions: PermissionsGroupEmploye.EmployeFormationLire,
  },
  {
    state: 'video',
    name: 'global.video',
    type: 'link',
    icon: 'play_lesson',
    permissions: PermissionsGroupEmploye.EmployeFormationLire
  },
  {
    state: 'formation-management',
    name: 'global.management',
    type: 'link',
    icon: 'movie_edit',
    permissions: PermissionsGroupEmploye.EmployeGestionFormation
  },
  {
    state: 'employe/dashboard',
    name: 'global.mainMenu',
    type: 'extLink',
    icon: 'arrow_back',
    permissions: PermissionsModuleEmploye.ModuleEmployeLire
  },
];

export const  AdminMenuItems: Menu[] = [
  {
    state: '',
    name: 'global.general',
    type: 'separator',
    icon: '',
    permissions: PermissionsModuleAdmin.ModuleAdminLire
  },
  {
    state: 'user',
    name: 'global.user',
    type: 'link',
    icon: 'person',
    permissions: PermissionsGroupAdmin.AdminGestionUtilisateurs
  },
  {
    state: 'group',
    name: 'global.group',
    type: 'link',
    icon: 'group',
    permissions: PermissionsGroupAdmin.AdminGestionUtilisateurs
  },
  {
    state: 'user-profile',
    name: 'global.userProfile',
    type:'link',
    icon:'account_box',
    permissions: PermissionsGroupAdmin.AdminGestionProfileUtilisateur
  },
  {
    state: 'employe/dashboard',
    name: 'global.mainMenu',
    type: 'extLink',
    icon: 'arrow_back',
    permissions: PermissionsModuleEmploye.ModuleEmployeLire
  },
];

export const  DefaultMenuItems: Menu[] = [
  {
    state: '',
    name: 'global.general',
    type: 'separator',
    icon: '',
    permissions: PermissionsModuleAdmin.ModuleAdminLire
  },
];



