import { Component, Input, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BusService } from 'src/app/shared/services/bus.service';
import { TinMessageService } from '../../shared/services/tin-message.service';
import { BasePermissions, HeaderAction, MessageAction, ReponseConfirmation, SetupHeaderDialog } from '../../shared/global';
import { skip } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'app-header-dialog',
  templateUrl: './header-dialog.component.html',
  styleUrls: ['./header-dialog.component.scss']
})
export class HeaderDialogComponent implements OnDestroy {
  private subscriptions = new Subscription();
  isButtonDeleteDisabled = false;
  isButtonOkDisabled = true;
  isButtonCancelDisabled = true;
  isExpanded = false;
  isMobile = false;

  constructor(
    private serviceBus: BusService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService,
    public messageService: TinMessageService){
      this.subscriptions.add(this.serviceBus.messages$().pipe(skip(1)).subscribe(x => {
        switch (x.MessageAction) {
          case MessageAction.DialogActionFormIsDirtyChange:
            if (x.MessageData.id === this.setup?.id) {
              this.setup.okButtonIsDisabled = !x.MessageData.isDirty;
              this.setup.cancelButtonIsDisabled = !x.MessageData.isDirty;              
            }
            break;
        }
      }));
      this.isMobile = this.deviceService.isMobile();
  }

  @Input() permissions!: BasePermissions;
  @Input() headerTitle = '';
  @Input() headerTitleSuffix = '';
  @Input() setup!: SetupHeaderDialog;


  toggleExpandDetails(): void {
    if (this.isExpanded) {
      this.serviceBus.sendMessage(MessageAction.HeaderDialogActionShrink, {id: this.setup.id});
    }
    else {
      this.serviceBus.sendMessage(MessageAction.HeaderDialogActionExpand, {id: this.setup.id});
    }
    this.isExpanded = !this.isExpanded;
  }

  delete(): void {
    this.subscriptions.add(this.messageService.openDialogConfirmation('global.delete', 'dialog.confirmationDelete').subscribe(x => {
      if (x && x.response === ReponseConfirmation.Yes) {
        this.serviceBus.sendMessage(MessageAction.HeaderDialogActionDelete, {id: this.setup.id});
      }
    }));

  }

  close(): void {
    this.serviceBus.sendMessage(MessageAction.HeaderDialogActionClose, {id: this.setup.id});
    this.isExpanded = false;
  }

  ok(): void {
    this.serviceBus.sendMessage(MessageAction.HeaderDialogActionOk, {id: this.setup.id});
  }

  cancel(): void {
    this.serviceBus.sendMessage(MessageAction.HeaderDialogActionCancel, {id: this.setup.id});
    this.setup.okButtonIsDisabled = true;
    this.setup.cancelButtonIsDisabled = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
