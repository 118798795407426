<div [formGroup]="form" class="dyn-form-component">
  <mat-form-field appearance="{{control.appearance ? control.appearance : 'outline'}}"
  [ngStyle]="control.style || false">
      <mat-label class="custom-mat-label">{{control.translatePrefixLabel + '.' + getName() | translate}}
          <span *ngIf = "formGroup?.invalid" class="required">*</span> 
      </mat-label>
      <input matInput 
      id="{{getControlId()}}"
      formControlName="{{control.keyName}}" 
      [errorStateMatcher]="errorState"
      [matDatepicker]="picker"
      [min]="control.min" 
      [max]="control.max"
      [readonly]="isReadOnly()"
      (dateChange)="callFunction()"
      >
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [disabled]="isReadOnly()"></mat-datepicker>
      <mat-error class="form-text text-danger" *ngIf="hasControlError">{{formGroup?.errorMessage}}</mat-error>
  </mat-form-field>
</div>