import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RxFormArray, RxFormBuilder, RxFormControl, RxFormGroup } from '@rxweb/reactive-form-validators';
import * as jsondiffpatch from 'jsondiffpatch';
import * as _ from 'lodash';
import moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, of, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { createDiffPatcher, removeProperty, UtilityService } from '../helper/utility.service';
import { ControlFormatType, ControlType, SetupDynamicForms } from './controls/control-config';

const modelControls: any = {};
const controlErrors = new Subject<any>();
let permissions: any = null;

@Injectable({
  providedIn: 'root'
})

export class DynamicFormsService {
  constructor(
    private fb: RxFormBuilder,
    private translate: TranslateService,
    private utilityService: UtilityService,
    private permissionService: NgxPermissionsService
  ) {
  }

  // Controls ----------------------------
  getControlsModel(modelName: string, isNewRecord = false, showReadonlyFields = false): any {
    if (!permissions) {
      permissions = this.permissionService.getPermissions();
    }
    let modelControlInstance: any = modelControls[modelName];
    if (modelControlInstance) {
      const KeyAll = Object.prototype.hasOwnProperty.call(modelControlInstance, 'all') ? modelControlInstance.all : {};
      let hasPermission = true;
      if (KeyAll && KeyAll.permissionToChange)
      {
        hasPermission = Object.keys(permissions).some(x => KeyAll.permissionToChange.includes(x));
      }
      KeyAll.readonly = hasPermission === false ? true : KeyAll.readonly === undefined ? false : KeyAll.readonly;
      KeyAll.hasPermissionToChange = hasPermission;

      for (const key in modelControlInstance) {
        if (Object.prototype.hasOwnProperty.call(modelControlInstance, key)) {
          const control = modelControlInstance[key];
          if (key !== 'all' && key !== 'modelName' && key !== 'errors') {
            if (control.permissionToChange) {
              hasPermission = Object.keys(permissions).some(x => control.permissionToChange.includes(x));
              control.readonly = !hasPermission;
            }
            control.parent = KeyAll;
            control.root = modelControlInstance;
            control.childControls = control.type === ControlType.FormGroup ?
              this.getControlsModel(control.className, isNewRecord, showReadonlyFields) : [];
          }
        }
      }
      if (isNewRecord){
        modelControlInstance = _.pickBy(modelControlInstance, (value: any) => !value.hideInNew && (!value.readonly || (value.readonly && showReadonlyFields)));
      } else {
        modelControlInstance = _.pickBy(modelControlInstance, (value: any) => !value.hideInEdit);
      }
      return _.sortBy(modelControlInstance, ['order']);
    }
    return [];
  }

  createControlModel(modelName: string, propertykey: string, propertyDetail: any): void{
    if (!modelControls[modelName]) {
      modelControls[modelName] = {};
      modelControls[modelName]['modelName'] = modelName;
      modelControls[modelName]['errors'] = {};
    }
    if (propertykey === 'all') {
      modelControls[modelName][propertykey] = {};
      Object.assign(modelControls[modelName][propertykey], propertyDetail);
    } else {
      modelControls[modelName][propertykey] = propertyDetail;
    }
  }


  // Form ----------------------------
  private modelForms: any = {};
  private formChanges: any = {};
  private modelFormsDataSnapshot: any = {};

  public getInstanceForm(modelName: string): RxFormGroup {
    return this.modelForms[modelName];
  }
  public setInstanceForm(modelName: string, form: RxFormGroup) {

    if (!this.modelForms[modelName]) {
      this.modelForms[modelName] = {};
      this.modelFormsDataSnapshot[modelName] = {};

    }
    this.modelForms[modelName] = form;
    this.setInstanceFormSnapshot(modelName, form.modelInstance);
  }

  public setInstanceFormSnapshot(modelName: string, data: any) {

    this.modelFormsDataSnapshot[modelName] = JSON.parse(JSON.stringify(data));

  }

  public listenForFormChanges(modelName: string): Subject<RxFormGroup> {
    if (!this.formChanges[modelName]) {
      this.formChanges[modelName] = new Subject<RxFormGroup>();
    }
    return this.formChanges[modelName];
  }

  public listenForControlErrors(): Subject<any> {
    return controlErrors;
  }

  public notifyControlErrors(modelUIName: string, controlKey: string, controlHasError: boolean, controlHasWarning:  boolean = false) {
    if (modelUIName) {
      modelControls[modelUIName]['errors'][controlKey] = controlHasError;
      const hasError =  Object.values(modelControls[modelUIName]['errors']).some(x => x === true);
      controlErrors.next({modelUIName, controlKey, hasError, hasWarning: controlHasWarning});
    }
  }

  public updateDataSource(modelName: string, entity: any, controls: any, recreateForm = true, formatValues = true): RxFormGroup{
    let form!: RxFormGroup;
    if (recreateForm) {
      form = this.fb.formGroup(entity) as RxFormGroup;
      this.setInstanceForm(modelName, form);
      this.setInstanceFormSnapshot(modelName, form.modelInstance);
     }
    const instanceForm = form ?? this.getInstanceForm(modelName);
    if (formatValues) {
      Object.keys(entity).forEach(name => {
        if (instanceForm && instanceForm.controls[name]) {
          const control = controls?.filter((ctrl: { keyName: string; }) => ctrl.keyName === name)[0];
          if (control && control.format) {
            if (control.format?.type === ControlFormatType.Date) {
              entity[name] = moment(entity[name]).format(control.format?.pattern);
            } 
          }
          if ((control && control.readonly) || (control && control.parent.readonly)) {
            instanceForm.controls[name].disable();
          }
          if (control && control.type === ControlType.Datepicker && entity[name]) {
            if (entity[name] === 'Invalid date') {
              entity[name] =  '';
            } else {
              entity[name] =  new Date (entity[name]);
            }
          }
          instanceForm.controls[name].patchValue(entity[name]);
        }
      });
    }
    this.formChanges[modelName]?.next(instanceForm);
    return instanceForm;
  }

  validatesForm(form: RxFormGroup): boolean {
    form.submitted = true;
    const errors = form.getErrorSummary(true);
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      if (!environment.production)
       console.log(errors);
      return false;
    }
  }

  postForm(form: RxFormGroup, serviceEntity: any, endpoint: string, 
          cleanEntitiesProperties = true, cleanEntitiesPropertiesException: string[] = [], 
          removeUndefinedFields: boolean = true, deletePropInsteadClean: boolean = false, 
          propToDeleteBeforeSave: string[] = [], action: string = ''): Observable<any> {
    // Use modelInstance to take values with sanitization
    const result = this.beforeSave(form.modelInstance, cleanEntitiesProperties, 
        cleanEntitiesPropertiesException, removeUndefinedFields, deletePropInsteadClean, propToDeleteBeforeSave); 
    
    return serviceEntity.upsert(result, action ? `${endpoint}/${action}` : endpoint);

  }
  deleteForm(id: any, model: any, serviceEntity: any, cleanEntitiesProperties = true, 
            cleanEntitiesPropertiesException: string[] = [], endpoint: string = '',
            deletePropInsteadClean: boolean = false, propToDeleteBeforeDelete: string[] = [], 
            action: string = '', audit: any = null): Observable<any>  {
    const item = new model();
    item.id = id;
    if (cleanEntitiesProperties) {
      this.cleanEntitiesProperties(item, cleanEntitiesPropertiesException, deletePropInsteadClean, propToDeleteBeforeDelete);
    }
    if (audit) {
      item.audit  = audit;
    }
    return serviceEntity.delete(item, action ? `${endpoint}/${action}` : endpoint);
  }

  formNewItem(formDynamic: SetupDynamicForms, modelName: string, dataSource: any): void{
    this.updateForm(formDynamic, dataSource, true, true, modelName);
  }

  disableForm(formDynamic: RxFormGroup): void {
    formDynamic.disable();
  }

  updateForm(formDynamic: SetupDynamicForms,  dataSource: any, recreateForm = true, formatValues = true, modelName: any = null): RxFormGroup{
   return this.updateDataSource(modelName ?? formDynamic?.modelToInitForm?.name, dataSource, formDynamic?.controls, recreateForm, formatValues);
  }

  updateControl(formDynamic: RxFormGroup, controlName: any, value: any, markAsDirty = true): void {
    formDynamic.controls[controlName]?.patchValue(value);
    if (markAsDirty) {
      formDynamic.markAsDirty();
    } 
  }

  updateControls(formDynamic: RxFormGroup, values: any,  markAsDirty = true): void {
    if (formDynamic && formDynamic.controls && values) {
      Object.keys(values).forEach(prop => {
        if (formDynamic.controls[prop])
        formDynamic.controls[prop]?.patchValue(values[prop]);
      })
      if (markAsDirty) {
        formDynamic.markAsDirty();
      } 
    }
  }

  updateControlLookup(setupFormDynamic: SetupDynamicForms, formDynamic: RxFormGroup,  controlName: any, value: any, valueLookUp?: any): void {
    const control = setupFormDynamic.controls.filter(w => w.keyName === controlName);
    if (control && control[0]) {
      formDynamic.controls[controlName]?.patchValue(value);
      if (control[0].type === 'textLookup' && setupFormDynamic.lookups) {
        setupFormDynamic.lookups[`${setupFormDynamic.modelToInitForm.name}${controlName}`] = of (valueLookUp || value);
      }
    }
  }

  public updateFormControlArray(formDynamic: RxFormGroup,  controlName: any, value: any[]): void {
    if (formDynamic && formDynamic.controls[controlName] && value) {
      formDynamic.controls[controlName].value.forEach((element: any) => {
        (formDynamic.controls[controlName] as RxFormArray).removeAt(element);
      });
      value.forEach(x => {
        const control = this.fb.formGroup(x);
        if (formDynamic.dirty) {
          control.markAsDirty();
        }
        (formDynamic.controls[controlName] as RxFormArray).push(control);
      });
    }
  }


  addGroupToFormControlArray(formDynamic: RxFormGroup,  controlName: any, value: any): void {
    (formDynamic.controls[controlName] as RxFormArray)?.push(this.fb.formGroup((value)));
  }

  removeItemFromFormControlArray(formDynamic: RxFormGroup,  controlName: any, index: number): void {
    (formDynamic.controls[controlName] as RxFormArray)?.removeAt(index);
  }


  loadControlArray(formDynamic: RxFormGroup,  controlName: any, value: any[], clearBeforeAdd = false): void {
    if (formDynamic?.controls[controlName]) {
      if (clearBeforeAdd) {
        formDynamic.controls[controlName].value.forEach((element: any) => {
          (formDynamic.controls[controlName] as RxFormArray).removeAt(element);
        });
      }
      value.forEach(x => {
        (formDynamic.controls[controlName] as RxFormArray)?.push(this.fb.formGroup((x)));
      });
    }
  }

  setCustomError(message: any, control: RxFormControl | RxFormGroup): void {
    control.setErrors({async: { message: this.translate.instant(message)}});
  }

  cleanCustomError(control: RxFormControl): void {
    control.updateValueAndValidity();

 }

  dispose(modelName: string) {
    const instanceForm = this.getInstanceForm(modelName) as any;
    if (instanceForm){
      if (this.formChanges && this.formChanges[modelName]) {
        this.formChanges[modelName].unsubscribe();
        delete this.formChanges[modelName]
      }
      delete this.modelForms[modelName];
      delete this.modelFormsDataSnapshot[modelName]
    }
  }

  private beforeSave(modelInstance: any, cleanEntitiesProperties: boolean, 
    cleanEntitiesPropertiesException: string[] = [], removeUndefinedFields: boolean = true, 
    deletePropInsteadClean: boolean = false, propToDeleteBeforeSave: string[] = []): any {
    let result = {};
    if (cleanEntitiesProperties) {
      if (removeUndefinedFields) {
        result = JSON.parse(JSON.stringify(modelInstance, (k, v) => v === undefined ? null : v)); // deep copy without remove undefined
      } else {
        result = JSON.parse(JSON.stringify(modelInstance)); // deep copy
      }

      this.cleanEntitiesProperties(result, cleanEntitiesPropertiesException, deletePropInsteadClean, propToDeleteBeforeSave);
    } else {
      result = Object.assign({}, modelInstance);
    }
    return result;
  }
  private cleanEntitiesProperties(modelInstance: any, exceptions: string[], deletePropInsteadClean: boolean = false, propToDelete: string[] = []): void {
    if (modelInstance && Object.keys(modelInstance).length > 0) {
      Object.keys(modelInstance).forEach ((key: any) => {
        if (propToDelete && propToDelete.includes(key)) {
          delete modelInstance[key];
        } else {
        if (modelInstance[key] && typeof(modelInstance[key]) === 'object') {
          if (modelInstance[key] instanceof Array) {
            if (modelInstance[key] && modelInstance[key].length > 0) {
              modelInstance[key].forEach((item: any) => {
                this.cleanEntitiesProperties(item, exceptions, deletePropInsteadClean, propToDelete);
              });
            }
          } else if (Object.keys(modelInstance[key]).length > 0) {
            if (!exceptions.includes(key)) {
              if (deletePropInsteadClean) {
                delete modelInstance[key];
              } else {
                modelInstance[key] = null;
              }
            } else {
              this.cleanEntitiesProperties(modelInstance[key], exceptions, deletePropInsteadClean, propToDelete);
            }
          }
        }
      }
      });
    }
  }

  
  getFormDeltaChanges(modelName: string, propertiesToIgnore: string[] = [], isDeleteAction = false, propertiesToIgnoreRecursively: string[] = []): any {
    const dataBeforeChanges = this.modelFormsDataSnapshot[modelName];
    const data = isDeleteAction ? {} : this.modelForms[modelName]?.modelInstance;

    propertiesToIgnore.push('audit');

    const diffDispatcher = createDiffPatcher(propertiesToIgnore);

    var left = JSON.parse(JSON.stringify(dataBeforeChanges), jsondiffpatch.dateReviver);
    var right = JSON.parse(JSON.stringify(data), jsondiffpatch.dateReviver);
    
    removeProperty(left, propertiesToIgnoreRecursively);
    removeProperty(right, propertiesToIgnoreRecursively);
    
    const delta = diffDispatcher.diff(left, right) as any;
    
    return delta;
  }

}
