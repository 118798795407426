import { AfterContentInit, Component, ElementRef, Input} from '@angular/core';
import { DynamicFormsService } from '../../dynamic-forms.service';
import { ControlButtonIconConfig } from '../control-config';
import { DynControlBase } from '../dyn-controls-base';

@Component({
  selector: 'app-dyn-button-icon',
  templateUrl: './dyn-button-icon.component.html',
  styleUrls: ['./dyn-button-icon.component.scss', '../dyn-controls.scss']
})
export class DynButtonIconComponent extends DynControlBase implements AfterContentInit {
  @Input() controlParent!: ControlButtonIconConfig | any;

  private badge: any;

  constructor(
    public override dynamicFormsService: DynamicFormsService,
    private el: ElementRef
  ) { 
    super(dynamicFormsService);
  }
  ngAfterContentInit(): void {
    setTimeout(() => {
      if (this.control.badgeIconName) {
        this.badge = this.el.nativeElement.querySelector('.mat-badge-content');
        if (this.badge) {
          this.badge.style.display = 'flex';
          this.badge.style.alignItems = 'center';
          this.badge.style.justifyContent = 'center';
          this.badge.style.left = '16px';
          this.badge.style.top = '4px';
          this.badge.style['background-color'] = 'black'
          this.badge.innerHTML = `<i class="material-icons" style="font-size: 12px">${this.control.badgeIconName}</i>`;
        }
      }
    }, 0);
  }

  showBadgeIconBasedContext(): void {
    let result = false;
    if (this.control) {
      result = this.control.badgeIconName !== '' && (this.control.showBadgeIconBasedContext?.fieldName === '-' ||
      (this.context[this.control.showBadgeIconBasedContext?.fieldName] !== undefined && 
        this.context[this.control.showBadgeIconBasedContext?.fieldName] === this.control.showBadgeIconBasedContext?.value))
    }
    if (this.badge) {
      this.badge.style.display = result ? 'flex' : 'none';
    }
  }
}
