import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, map, publishReplay, refCount, take, tap } from 'rxjs/operators';
import { HandleError, HttpErrorHandler } from './http-error-handler.service';

const CACHE_TIME = ((1000 * 60) * 60) * 8; // 8h

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json; charset=utf-8;',
    'Access-Control-Allow-Origin': '*', // check after / put same domain to dont use on prod
  })
};

export class ApiBaseService<T> {
  protected handleError: HandleError;
  protected cache: any = {};

  constructor(
    protected http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    protected url: string,
    protected endpoint: string,
  ) {
    this.handleError = httpErrorHandler.createHandleError('BaseService');
  }


  //return list of items
  list(actionWithEndpoint?: string, odataExpression?: string, forceReload?: boolean, isPagination?: boolean): Observable<T[]> {
    return this.requestWithcache(forceReload ?? false, this.getAction(actionWithEndpoint, odataExpression), isPagination ?? false)
    .pipe(
      catchError(this.handleError('list', null))
    );
  }
  //return just one item
  get(id?: any, actionWithEndpoint?: string, odataExpression?: string, forceReload?: boolean): Observable<any> {
    return this.requestWithcache(forceReload ?? false, this.getAction(actionWithEndpoint, odataExpression, id), false)
      .pipe(
        map(x => Array.isArray(x) ? x[0] : x),
        catchError(this.handleError('get', null))
      );
  }
  getBlob(actionWithEndpoint: string): Observable<any> {
    return this.http.get(`${this.url}/${actionWithEndpoint ? actionWithEndpoint : this.endpoint}`, {responseType: 'blob' })
    .pipe(
      catchError(this.handleError('getBlob'))
    )
 }
  post(payload: any, actionWithEndpoint?: string): Observable<any> {
    return this.http.post<T | T[] | any>(`${this.url}/${actionWithEndpoint ? actionWithEndpoint : this.endpoint}`, payload)
      .pipe(
        catchError(this.handleError('post', null))
      );
  }
  upsert(item: T | T[] | any, actionWithEndpoint?: string): Observable<T | any> {
    return this.http.post<T>(`${this.url}/${actionWithEndpoint ? actionWithEndpoint : this.endpoint}`, item, httpOptions)
      .pipe(
        catchError(this.handleError('upsert', null))
      );
  }

  delete(item: T, actionWithEndpoint?: string): Observable<any> {
    return this.http.request('delete', `${this.url}/${actionWithEndpoint ? actionWithEndpoint : this.endpoint}`, { body: item })
      .pipe(
        catchError(this.handleError('Delete'))
      );
  }

  // GET Items from the server and put on the cache
  protected requestWithcache(forceReload: boolean, endpoint: string, isPagination: boolean): any {
    const httpOptionsPage = {
      headers: new HttpHeaders({
        Page:  isPagination.toString()
      })
    };
    if (!this.cache[endpoint] || forceReload) {
      this.cache[endpoint] = this.http.get<T[]>(`${this.url}/${endpoint}`, httpOptionsPage)
        .pipe(
          publishReplay(1, CACHE_TIME),
          refCount(),
          take(1),
          catchError(this.handleError('refresh', []))
        );
    }
    return this.cache[endpoint];
  }
  private getAction(action?: string, odataExpression?: string, id?: string) {
    if (id) {
      return `${this.endpoint}/${id}`;
    } else if (action) {
      return odataExpression ? `${action}${odataExpression}` : action;
    } else {
      return odataExpression ? `${this.endpoint}${odataExpression}` : this.endpoint;
    }
  }

  clearCache() {
    this.cache = {};
  }
}
