<div [formGroup]="form">
    <div class="wrap" [ngClass]="{'errorField': hasControlError}">
        <mat-label class="custom-mat-label">{{(control.translatePrefixLabel + '.' + getName() | translate)}}
            <span *ngIf = "formGroup?.invalid" class="required">*</span> 
        </mat-label> 
        <div class="editor">
            <ngx-editor-menu *ngIf="!isReadOnly()" [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
            <ngx-editor 
                [editor]="editor" 
                [formControl]="form.controls[control?.keyName]" 
                [outputFormat]="'html'"
                id="{{getControlId()}}"
                placeholder="{{control.placeHolder ? (control.translatePrefixLabel + '.' + control.placeHolder | translate) : ''}}"
                disabled="readOnly"
            ></ngx-editor>
        </div>
    </div>
    <mat-error class="form-text text-danger" *ngIf="hasControlError">{{formGroup?.errorMessage}}</mat-error>
</div>
