import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/services/api-base-service';
import { BusService } from 'src/app/shared/services/bus.service';
import { HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class UserEmployeeService extends ApiBaseService<any>{

  constructor(http: HttpClient, httpErrorHandler: HttpErrorHandler, busService: BusService ) {
    super(
      http,
      httpErrorHandler,
      busService.apiEndpoint,
      'UserEmployee',
      );
  }
}
