import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Module } from 'src/app/shared/global';
import { LibAuthService } from 'ngx-mat-auth';
import { AuthService } from '../auth.service';
import { BusService } from '../../shared/services/bus.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent  implements OnInit {
  constructor(
    private router: Router,
    private zone: NgZone,
    private tinAuthService: LibAuthService,
    private authService: AuthService,
    private serviceBus: BusService) {
    // Used for listening to login events
    tinAuthService.listenLoginEvents('auth', ({ payload: { event, data } }) => {
      if (event === 'cognitoHostedUI' || event === 'signedIn') {
        this.zone.run(async () => {
          await this.checkRouteAndRedirectUser();
        } );
      }
    });
  }
  async ngOnInit(): Promise<void> {
    // when user comes to login page again
    if (await this.tinAuthService.isUserLoggedIn()) {
      await this.checkRouteAndRedirectUser();
    } else {
      if (this.serviceBus.module === Module.Client) {
        window.location.replace('https://www.inter-nord.com');
      } else {
        this.router.navigate(['/'], { replaceUrl: true });
      }
    }
  }
  private async checkRouteAndRedirectUser (){
    const initialRoute = this.serviceBus.initialRoute;
    if (initialRoute) {
      this.router.navigate([`/${initialRoute}`], { replaceUrl: true }); 
    } else {
      const module = await this.authService.getFirstAuthorizedModule();
      if (module) {
        this.router.navigate([`/${module}`], { replaceUrl: true }); 
      } else {
        this.authService.unauthorizedUser();
      }
    }
  }

}
