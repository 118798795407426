import { AfterContentChecked, Component, Input } from '@angular/core';
import { DynControlBase } from '../dyn-controls-base';

@Component({
  selector: 'app-dyn-switch',
  templateUrl: './dyn-switch.component.html',
  styleUrls: ['./dyn-switch.component.scss', '../dyn-controls.scss']
})
export class DynSwitchComponent extends DynControlBase implements AfterContentChecked {
  @Input() actions: any;

  ngAfterContentChecked(): void {
    this.afterContentChecked()
  }
}
