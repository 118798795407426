import { BusService } from 'src/app/shared/services/bus.service';
import { Subscription } from 'rxjs';
import { Component, ComponentFactoryResolver, Inject, ViewContainerRef, OnDestroy, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogEntityManager, MessageAction, ReponseDialogEntityManager, SetupHeaderDialog } from '../../global';
import { skip } from 'rxjs/operators';


@Component({
  selector: 'app-tin-dialog-entity-manager',
  templateUrl: './tin-dialog-entity-manager.component.html',
  styleUrls: ['./tin-dialog-entity-manager.component.scss']
})
export class TinDialogEntityManagerComponent implements OnDestroy, AfterViewInit {
  public setupHeaderDialog: SetupHeaderDialog = {};

   public componentRef:any;

   @ViewChild("container", { read: ViewContainerRef }) container: any;
   
  private subscriptions = new Subscription();
 
  constructor(
    private dialogRef: MatDialogRef<TinDialogEntityManagerComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogEntityManager,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private serviceBus: BusService,
  ) { 
    this.setupHeaderDialog.id = this.dialogRef.id;
    this.subscriptions.add(this.serviceBus.messages$().pipe(skip(1)).subscribe(result => {
      switch (result.MessageAction) {
       case MessageAction.HeaderDialogActionExpand:
        this.expand(result.MessageData.id);
        break;
      case MessageAction.HeaderDialogActionShrink:
        this.shrink(result.MessageData.id);
        break;
      case MessageAction.HeaderDialogActionClose:
        this.close(result.MessageData.id);
        break;
      case MessageAction.HeaderDialogActionCancel:
        this.cancel(result.MessageData.id);
        break;
      case MessageAction.DialogActionFormOk:
        this.ok(result.MessageData.item, result.MessageData.id);
        break;
      case MessageAction.HeaderDialogActionDelete:
        this.delete(result.MessageData.item, result.MessageData.id);
        break;        
      }
    }));
  }
  ngAfterViewInit(): void {
    this.setDefaultValues();
    this.loadComponent();
  }
  
  setDefaultValues() {
    this.setupHeaderDialog.showDeleteButton = this.data.showDeleteButton == undefined ? true : this.data.showDeleteButton;
    this.setupHeaderDialog.showOkButton = this.data.showOkButton == undefined ? true : this.data.showOkButton;
    this.setupHeaderDialog.showSaveAndCloseButton = this.data.showSaveAndCloseButton == undefined ? false : this.data.showSaveAndCloseButton;
    this.setupHeaderDialog.showExpandButton = this.data.showExpandButton == undefined ? true : this.data.showExpandButton;
    this.setupHeaderDialog.showCancelButton = this.data.showCancelButton == undefined ? true : this.data.showCancelButton;
    this.setupHeaderDialog.okButtonIsDisabled = true;
    this.setupHeaderDialog.cancelButtonIsDisabled = true;
  }

  loadComponent(): void {
    let factory = this.componentFactoryResolver.resolveComponentFactory(this.data?.component)
    this.container.clear();
    this.componentRef = this.container.createComponent(factory);
    if (this.componentRef.instance) {
      this.componentRef.instance.dialogEntityManager = this.data;
    }
    this.changeDetectorRef.detectChanges();
  }

  ok(item: any, dialogId: string): void {
    if (dialogId === this.dialogRef.id) { 
      this.dialogRef.close({response: ReponseDialogEntityManager.Ok, item });
    }
  }

  cancel(dialogId: string): void {
    if (dialogId === this.dialogRef.id) {
      this.componentRef.destroy();
      this.container.clear();
      this.loadComponent(); 
    }
  }

  delete(item: any, dialogId: string): void {
    if (dialogId === this.dialogRef.id) {
      this.dialogRef.close({response: ReponseDialogEntityManager.Delete, item });
    }
  }

  close(dialogId: string): void {
    if (dialogId === this.dialogRef.id) { // prise en charge de plusieurs dialogues
      this.dialogRef.close({response: ReponseDialogEntityManager.Cancel});
    }
  }

  expand(dialogId: string): void {
    if (dialogId === this.dialogRef.id)  {
      this.dialogRef.updateSize('100%', '100%');
    }
  }

  shrink(dialogId: string): void {
    if (dialogId === this.dialogRef.id)  {
      this.dialogRef.updateSize(this.data?.width, this.data?.height);
    }
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.subscriptions.unsubscribe();
  }

}
