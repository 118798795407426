<mat-card *ngIf="showForm">
  <mat-card-content>
    <mat-card-title>{{setup.formTitle}}</mat-card-title>
    <form [formGroup]="form" [class.readonly-form]="readonlyForm()" *ngIf="form">
        <div fxLayout="row wrap" fxLayoutGap="8px grid" fxLayoutAlign="start start">
          <ng-container *ngFor="let control of getControls(form)">
              <div *ngxPermissionsOnly="control.permission" fxFlex="100" [fxFlex.gt-sm]="control.mdWidth" [fxFlex.gt-md]="getLgWidth(control)" [fxFlexAlign]="getFlexAlign(control)">
                <ng-container
                [ngTemplateOutlet]="controls"
                [ngTemplateOutletContext]="{control: control, formGroup: form}">
                </ng-container>
              </div>
          </ng-container>
        </div>

        <div fxLayout="row wrap" fxLayoutGap="5px grid" >
          <ng-container *ngFor="let controlButton of buttonsActions">
            <div *ngxPermissionsOnly="controlButton.permission">
              <app-dyn-button *ngIf="controlButton.type==='button' && showControl(controlButton, form)" 
              [control]="controlButton" [form]="form" [formArrayIndex]="formArrayIndex" [context]="setup.context"></app-dyn-button>

              <app-dyn-button-icon *ngIf="controlButton.type==='buttonIcon' && showControl(controlButton, form)"  
              [control]="controlButton" [form]="form" [formArrayIndex]="formArrayIndex"  [context]="setup.context"></app-dyn-button-icon>
            </div>
          </ng-container>
        </div>
    </form>
  </mat-card-content>
</mat-card>

<ng-template #controls let-control="control" let-formGroup="formGroup" >
  <div [ngSwitch]="control.type" *ngIf="showControl(control, formGroup)">
    
      <ng-container *ngSwitchCase="'formGroup'" [ngTemplateOutlet]="recursiveForm" [ngTemplateOutletContext]="{control: control, formGroup: formGroup}"></ng-container>
    
      <app-dyn-dropdown *ngSwitchCase="'dropdown'" [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex" [context]="setup.context" 
      [list]="formArrayIndex >= 0 && control.useControlFormArray ? 
      setup.dropdowns[formGroup.model.name + control.keyName + formArrayIndex] : 
      setup.dropdowns[formGroup.model.name + control.keyName]"
      [listSelectedItems]="formArrayIndex >= 0 && control.useControlFormArray ? 
      setup.dropdowns[formGroup.model.name + control.keyName + formArrayIndex + 'ListSelectedItems'] : 
      setup.dropdowns[formGroup.model.name + control.keyName + 'ListSelectedItems']"></app-dyn-dropdown>

      <app-dyn-textlookup *ngSwitchCase="'textLookup'" [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex" 
      [context]="setup.context" [itemLookup]="setup.lookups[formGroup.model.name + control.keyName]"></app-dyn-textlookup>

      <app-dyn-switch *ngSwitchCase="'switch'" [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex"
      [context]="setup.context"></app-dyn-switch>
      
      <app-dyn-checkbox *ngSwitchCase="'checkbox'" [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex"
      [context]="setup.context"></app-dyn-checkbox>

      <app-dyn-datepicker *ngSwitchCase="'datepicker'" [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex"
      [context]="setup.context"></app-dyn-datepicker>
      
      <app-dyn-text-area *ngSwitchCase="'textarea'" [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex"
      [context]="setup.context"></app-dyn-text-area>

      <app-dyn-button *ngIf="control.type==='button' && control.showAtBottom !== true"  [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex"
      [context]="setup.context" [actions]="setup.actions"></app-dyn-button>
    
      <app-dyn-button-icon *ngIf="control.type==='buttonIcon' && control.showAtBottom !== true" [context]="setup.context"
      [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex"></app-dyn-button-icon>

      <app-dyn-input *ngSwitchCase="'textbox'" [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex" [context]="setup.context" 
      [autocompleteItems]="control.autocomplete ? formArrayIndex >= 0 ?
      setup.dropdowns[formGroup.model.name + control.keyName + formArrayIndex] : setup.dropdowns[formGroup.model.name + control.keyName] : ''"
      ></app-dyn-input>

      <app-dyn-editor *ngSwitchCase="'editor'" [control]="control" [form]="formGroup" [formArrayIndex]="formArrayIndex"
      [context]="setup.context"></app-dyn-editor>

      <app-dyn-divider *ngSwitchCase="'divider'" [control]="control" [form]="form" [formArrayIndex]="formArrayIndex" 
      [context]="setup.context"></app-dyn-divider>

      <app-dyn-custom *ngSwitchCase="'custom'" [control]="control" [form]="form" [formArrayIndex]="formArrayIndex"
      [context]="setup.context"></app-dyn-custom>

    </div>
</ng-template>

<ng-template #recursiveForm let-control="control" let-formGroup="formGroup">
  <div *ngIf="formGroup.get(control.keyName)" [formGroup]="formGroup.get(control.keyName)" style="flex: 1 1 100%">
    <div fxLayout="row wrap" fxLayoutGap="5px grid">
      <ng-container *ngFor="let childControl of control.childControls;">
        <div *ngxPermissionsOnly="childControl.permission" fxFlex="100" [fxFlex.gt-sm]="getMdWidth(childControl)" [fxFlex.gt-md]="getLgWidth(childControl)">
          <ng-container [ngTemplateOutlet]="controls" [ngTemplateOutletContext]="{control: childControl, formGroup: formGroup.get(control.keyName)}"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
<!-- <div >
  <div> {{ form.modelInstance | json }}</div>
  <div> {{ form.value | json }}</div>
  <span>pristine: {{form.pristine}} - </span>
  <span>status: {{form.status}} - </span>
  <span>changing: {{form.changing}} - </span>
  <span>touched: {{form.touched}} - </span>
  <span>dirty: {{form.dirty}}</span>
  <span> Isdirty: {{form.isDirty()}}</span>
</div> -->