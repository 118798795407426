import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '@progress/kendo-angular-l10n';

@Injectable()
export class KendoTranslateService extends MessageService {
  constructor(private translate: TranslateService) {
    super();
  }
  override get(key: string): string {
    return this.translate.instant(key) === key ? undefined :  this.translate.instant(key);
  }
}
