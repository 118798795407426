<div [formGroup]="form" class="dyn-form-component" >
    <div style="position: relative;">
        <mat-form-field 
        appearance="{{control.appearance ? control.appearance : 'outline'}}"
        [ngStyle]="control.style || false"
        >
            <mat-label class="custom-mat-label">{{control.translatePrefixLabel + '.' + getName() | translate}} 
                <span *ngIf = "formGroup?.invalid" class="required">*</span> 
            </mat-label> 
            <textarea
            matInput formControlName="{{control?.keyName}}"
            rows="{{control?.rows}}"
            (change)="callFunction()"
            [errorStateMatcher]="errorState"
            [readonly]="isReadOnly()"
            placeholder="{{control.placeHolder ? (control.translatePrefixLabel + '.' + control.placeHolder | translate) : ''}}"
            id="{{getControlId()}}"
            ></textarea>
            <mat-error class="form-text text-danger" *ngIf="hasControlError">{{formGroup?.errorMessage}}</mat-error>
        </mat-form-field>
    </div>
</div>