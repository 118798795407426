import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BusService } from 'src/app/shared/services/bus.service';
import { TinMessageService } from 'src/app/shared/services/tin-message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    public authService: AuthService,
    private messageService: TinMessageService,
    private serviceBus: BusService
  ) { }

  ngOnInit(): void {
    if (this.authService.isUnauthorizedUser) {
      this.messageService.showUnauthorizedMessage();
    } else {
      this.login();
    }
  }

  login(): void {
    this.authService.hasAccessModuleClient().then(hasAccessModuleClient => {
      if (hasAccessModuleClient) {
        this.router.navigate(['/client']);
      } else {
        this.authService.hasAccessModuleEmploye().then(hasAccessModuleEmploye => {
          if (hasAccessModuleEmploye) {
            this.router.navigate(['/employe']);
            this.serviceBus.lastTimePrivateZoneLoginInMinutes = new Date();
          } else {
            this.router.navigate(['/admin']);
          }
        });
      }
    });
  }
}
