import { AfterContentChecked, Component } from '@angular/core';
import { DynControlBase } from '../dyn-controls-base';

@Component({
  selector: 'app-dyn-datepicker',
  templateUrl: './dyn-datepicker.component.html',
  styleUrls: ['./dyn-datepicker.component.scss', '../dyn-controls.scss']
})
export class DynDatepickerComponent extends DynControlBase implements AfterContentChecked {
  
  ngAfterContentChecked(): void {
    this.afterContentChecked();
  }
  
}
