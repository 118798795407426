import { AfterContentChecked, Component, ElementRef, Input, OnInit, SimpleChanges } from '@angular/core';
import { createMask } from '@ngneat/input-mask';
import { Observable } from 'rxjs';
import { DynControlBase } from '../dyn-controls-base';

@Component({
  selector: 'app-dyn-input',
  templateUrl: './dyn-input.component.html',
  styleUrls: ['./dyn-input.component.scss', '../dyn-controls.scss']
})

export class DynInputComponent extends DynControlBase implements AfterContentChecked, OnInit {
  @Input() autocompleteItems: Observable<any> = new Observable<any>();

  inputMask: any;

  ngOnInit(): void {
    if (this.control?.inputMask) {
      if (this.control?.isInputMaskRegex) {
        this.inputMask = createMask({regex: this.control?.inputMask, autoUnmask: this.control?.autoUnmask});
      } else {
        if (this.control?.isInputMaskDateTime) {
          this.inputMask = createMask<Date>({
            alias: 'datetime',
            inputFormat: this.control?.inputMask,
            parser: (value: string): any => {
              const values = value.split(' ');
              const valuesDate = values[0].split('/');
              const valuesTime = values[1].split(':')
              const year = +valuesDate[2];
              const month = +valuesDate[1] - 1;
              const day = +valuesDate[0];
              const hours = +valuesTime[0];
              const minutes = +valuesTime[1];
              if (!isNaN(year) && !isNaN(month) && !isNaN(day) && !isNaN(hours) && !isNaN(minutes)) {
                return new Date(year, month, day, hours, minutes);
              }
            }});
        } else {
          this.inputMask = createMask({mask: this.control?.inputMask, autoUnmask: this.control?.autoUnmask});
        }
      }
    } else {
      this.inputMask = false;
    }
  }

  ngAfterContentChecked(): void {
    this.afterContentChecked();
  }
}
