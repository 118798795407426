import { Observable, of } from 'rxjs';
import { AfterContentChecked, Component, Input } from '@angular/core';
import { DynControlBase } from '../dyn-controls-base';


@Component({
  selector: 'app-dyn-textlookup',
  templateUrl: './dyn-textlookup.component.html',
  styleUrls: ['./dyn-textlookup.component.scss', '../dyn-controls.scss']
})
export class DynTextlookupComponent extends DynControlBase implements AfterContentChecked {
  @Input() itemLookup: Observable<any> =  new Observable<any>();

  ngAfterContentChecked(): void {
    this.afterContentChecked()
  }

  clear(): void {
    const control = this.form.get(this.control.keyName);
    control.patchValue(null);
    this.itemLookup = of(null);
    this.form.markAsDirty();
  }
}
