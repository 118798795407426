import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Notification } from './notification';
import { Language } from '../global';

import { ApiBaseService } from '../services/api-base-service';
import { HttpErrorHandler } from 'src/app/shared/services/http-error-handler.service';
import { BusService } from '../services/bus.service';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { Module } from '../global';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends ApiBaseService<Notification | any>{
  dialogRef: any;

  constructor(
    http: HttpClient,
    httpErrorHandler: HttpErrorHandler,
    private serviceBus: BusService,
    public dialog: MatDialog,
    private overlay: Overlay ) {
    super(
      http,
      httpErrorHandler,
      serviceBus.apiEndpoint,
      'Notification',
      );
  }

  private async getMessages(): Promise<Notification[]>{
    const action = this.serviceBus.module === Module.Client ? 'Notification' : 'Notification/GetAllNotificationEmploye';
    const notifications: any =  await this.list(action, undefined, true).toPromise().then();
    return  notifications.map((item: any) => {
        if (this.serviceBus.currentLanguage === Language.English) {
          item.expediteur = item.expediteurEN;
          item.sujet = item.sujetEN;
          item.corps = item.corpsEN;
          item.tag = item.tagEN;
        }
        if (this.serviceBus.module === Module.Client) {
          if (item.notificationClients && item.notificationClients[0]) {
            item.estLu = item.notificationClients[0].estLu;
            item.estSupprime = item.notificationClients[0].estSupprime;
          }
        }
        if (this.serviceBus.module === Module.Employe) {
          if (item.notificationEmployes && item.notificationEmployes[0]) {
            item.estLu = item.notificationEmployes[0].estLu;
            item.estSupprime = item.notificationEmployes[0].estSupprime;
          }
        }
        return item;
    });
}

  public async openDialog(component: any): Promise<void> {
    const notifications = await this.getMessages();
    this.dialogRef = this.dialog.open(component, {
      height: '600px',
      width: '800px',
      data: {
        _notifications: notifications
      },
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
  }
}
