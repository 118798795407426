import { Directive, Input } from "@angular/core";
import { DynamicFormsService } from "../dynamic-forms.service";

@Directive()
export abstract class DynControlBaseDirective {
  @Input() form: any;
  @Input() control: any;
  @Input() context: any;
  @Input() debug: any;
  @Input() formArrayIndex!: number;

  constructor(
    protected dynamicFormsService: DynamicFormsService
  ) { 
  }
}