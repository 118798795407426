import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynButtonIconComponent } from './controls/dyn-button-icon/dyn-button-icon.component';
import { DynButtonComponent } from './controls/dyn-button/dyn-button.component';
import { DynCheckboxComponent } from './controls/dyn-checkbox/dyn-checkbox.component';
import { DynCustomComponent } from './controls/dyn-custom/dyn-custom.component';
import { DynDatepickerComponent } from './controls/dyn-datepicker/dyn-datepicker.component';
import { DynDividerComponent } from './controls/dyn-divider/dyn-divider.component';
import { DynDropdownComponent } from './controls/dyn-dropdown/dyn-dropdown.component';
import { DynEditorComponent } from './controls/dyn-editor/dyn-editor.component';
import { DynInputComponent } from './controls/dyn-input/dyn-input.component';
import { DynSwitchComponent } from './controls/dyn-switch/dyn-switch.component';
import { DynTextlookupComponent } from './controls/dyn-textlookup/dyn-textlookup.component';
import { DynamicFormBuildComponent } from './dynamic-form-build/dynamic-form-build.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from '@ngneat/input-mask';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { NgxEditorConfig, NgxEditorModule } from 'ngx-editor';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MaterialModule } from 'src/app/material/material-module';
import { KendoModule } from 'src/app/kendo/kendo.module';
import { DynTextAreaComponent } from './controls/dyn-textarea/dyn-text-area.component';

export const EDITOR_LOCALE: NgxEditorConfig = {
  locals: {
    bold: 'Gras',
    italic: 'Italique',
    code: 'Code',
    blockquote: 'Bloc de citation',
    underline: 'Sousligné',
    strike: 'Barré',
    bullet_list: 'Liste à puce',
    ordered_list: 'Liste numerotée',
    heading: 'Titre',
    h1: 'Titre 1',
    h2: 'Titre 2',
    h3: 'Titre 3',
    h4: 'Titre 4',
    h5: 'Titre 5',
    h6: 'Titre 6',
    align_left: 'Aligner à gauche',
    align_center: 'Centrer',
    align_right: 'Aligner à droite',
    align_justify: 'Justifier',
    text_color: 'Couleur de police',
    background_color: 'Couleur de surlignage du texte',
    insertLink: 'Insérer un lien',
    removeLink: 'Retirer un lien',
    insertImage: 'Insérer une image',
    url: 'URL',
    text: 'Texte',
    openInNewTab: 'Ouvrir dans un nouvel onglet',
    insert: 'Inserer',
    altText: 'Texte alternatif',
    title: 'Titre',
    remove: 'Retirer',
  },
};
export function editorLocaleFactory(translate: TranslateService, config: NgxEditorConfig) {
  const result = () => {
    setTimeout(() => {
      if (config && config.locals) {
        config.locals['bold'] = translate.instant('editor.bold');
        config.locals['italic'] = translate.instant('editor.italic');
        config.locals['code'] = translate.instant('editor.code');
        config.locals['blockquote'] = translate.instant('editor.blockquote');
        config.locals['underline'] = translate.instant('editor.underline');
        config.locals['strike'] = translate.instant('editor.strike');
        config.locals['bullet_list'] = translate.instant('editor.bullet_list');
        config.locals['ordered_list'] = translate.instant('editor.ordered_list');
        config.locals['heading'] = translate.instant('editor.heading');
        config.locals['h1'] = translate.instant('editor.h1');
        config.locals['h2'] = translate.instant('editor.h2');
        config.locals['h3'] = translate.instant('editor.h3');
        config.locals['h4'] = translate.instant('editor.h4');
        config.locals['h5'] = translate.instant('editor.h5');
        config.locals['h6'] = translate.instant('editor.h6');
        config.locals['align_left'] = translate.instant('editor.align_left');
        config.locals['align_center'] = translate.instant('editor.align_center');
        config.locals['align_right'] = translate.instant('editor.align_right');
        config.locals['align_justify'] = translate.instant('editor.align_justify');
        config.locals['text_color'] = translate.instant('editor.text_color');
        config.locals['background_color'] = translate.instant('editor.background_color');
        config.locals['url'] = translate.instant('editor.url');
        config.locals['text'] = translate.instant('editor.text');
        config.locals['openInNewTab'] = translate.instant('editor.openInNewTab');
        config.locals['insert'] = translate.instant('editor.insert');
        config.locals['altText'] = translate.instant('editor.altText');
        config.locals['title'] = translate.instant('editor.title');
        config.locals['remove'] = translate.instant('editor.remove');
      }
    }, 1000);
  };
  return result;
}

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    KendoModule,
    TranslateModule,
    FormsModule,
    RxReactiveFormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxPermissionsModule.forChild(),
    NgxEditorModule.forChild(EDITOR_LOCALE),
    InputMaskModule,
  ],
  declarations: [
    DynamicFormBuildComponent,
    DynInputComponent,
    DynTextAreaComponent,
    DynDropdownComponent,
    DynTextlookupComponent,
    DynSwitchComponent,
    DynCheckboxComponent,
    DynDatepickerComponent,
    DynButtonComponent,
    DynButtonIconComponent,
    DynEditorComponent,
    DynDividerComponent,
    DynCustomComponent
  ],
  exports: [
    CommonModule,
    MaterialModule,
    KendoModule,
    TranslateModule,
    FormsModule,
    RxReactiveFormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxPermissionsModule,
    NgxEditorModule,
    InputMaskModule,
    DynamicFormBuildComponent,
    DynInputComponent,
    DynTextAreaComponent,
    DynDropdownComponent,
    DynTextlookupComponent,
    DynSwitchComponent,
    DynCheckboxComponent,
    DynDatepickerComponent,
    DynButtonComponent,
    DynButtonIconComponent,
    DynEditorComponent,
    DynDividerComponent,
    DynCustomComponent
  ],
  providers: [
    { provide: EDITOR_LOCALE, useValue: EDITOR_LOCALE},
    {
      provide: APP_INITIALIZER,
      useFactory: editorLocaleFactory,
      deps: [TranslateService, EDITOR_LOCALE],
      multi: true
    },
  ],
})
export class DynamicFormsModule { }
