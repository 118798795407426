import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

import { Language, MessageAction, MessageBus, Module, StorageKey } from '../global';
import { StorageService } from '../helper/storage.service';
import { Console } from 'console';

const DEFAULT_LANGUAGE = 'fr';

interface AuthUser {
  username: string;
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class BusService implements OnDestroy {
  private subject$: any;
  constructor(private storage: StorageService) {
    this.subject$ = new BehaviorSubject(MessageBus);
  }


  public checkUserPrivateZone = false;
  public get lastTimePrivateZoneLoginInMinutes(): any {
    let datetime = this.storage.secureSessionStorage.getItem(StorageKey.LastTimePrivateZoneLogin);
    if (datetime) {
      datetime = new Date(datetime);
    } else {
      this.lastTimePrivateZoneLoginInMinutes = new Date();
      datetime = new Date();
    }
    const difMsec: number = (new Date).getTime() - datetime.getTime();
    const mm = Math.floor(difMsec / 1000 / 60);
    return mm;
  }
  public set lastTimePrivateZoneLoginInMinutes(date: Date) {
    this.storage.secureSessionStorage.setItem(StorageKey.LastTimePrivateZoneLogin, date);
  }


  public get module(): Module {
    return this.storage.secureSessionStorage.getItem(StorageKey.Module);
  }
  public set module(mod: Module) {
    this.storage.secureSessionStorage.setItem(StorageKey.Module, mod);
  }

  public get subModule(): Module | any {
    return this.storage.secureSessionStorage.getItem(StorageKey.SubModule);
  }
  public set subModule(subMod: Module | any) {
    const subModule = subMod || '';
    this.storage.secureSessionStorage.setItem(StorageKey.SubModule, subModule);
  }

  public get initialRoute(): string {
    return this.storage.secureSessionStorage.getItem(StorageKey.InitialRoute);
  }
  public set initialRoute(initialRoute: string) {
    this.storage.secureSessionStorage.setItem(StorageKey.InitialRoute, initialRoute);
  }


  public get currentLangTraduction(): string{
    if (this.currentLanguage === DEFAULT_LANGUAGE) {
      return 'fr';
    }
    return 'en';
  }

  public get currentLanguage(): Language {
    const lang =  this.storage.secureLocalStorage.getItem(StorageKey.CurrentLanguage);
    return lang ? lang : DEFAULT_LANGUAGE;
  }
  public set currentLanguage(lang: Language) {
    this.storage.secureLocalStorage.setItem(StorageKey.CurrentLanguage, lang);
  }

  private _hasUnreadNotification: boolean = false;
  public get hasUnreadNotification(): boolean {
    return this._hasUnreadNotification;
  }
  public set hasUnreadNotification(v: boolean) {
    this._hasUnreadNotification = v;
  }

  private _unreadNotificationCount:number = 0;
  public get unreadNotificationCount(): number {
    return this._unreadNotificationCount;
  }
  public set unreadNotificationCount(c: number) {
    this._unreadNotificationCount = c;
  }


  private _hasUnsavedChanges = false;
  public get hasUnsavedChanges(): boolean {
    return this._hasUnsavedChanges;
  }
  public set hasUnsavedChanges(v: boolean) {
    this._hasUnsavedChanges = v;
  }


  public get apiEndpoint(): string {
    return (this.module === Module.Client ? environment.baseURLExtranetAPI : environment.baseURLCorporateAPI);
  }

  private _clientId!: number;
  public get clientId(): number {
    return this._clientId;
  }
  public set clientId(v: number) {
    this._clientId = v;
  }

  private _groupeClientId!: number | null;
  public get groupeClientId(): number | null {
    return this._groupeClientId;
  }
  public set groupeClientId(v: number | null) {
    this._groupeClientId = v;
  }

  private _clientLanguageId!: number;
  public get clientLanguageId(): number {
    return this._clientLanguageId;
  }
  public set clientLanguageId(v: number) {
    this._clientLanguageId = v;
  }

  private _clientPaysId!: number;
  public get clientPaysId(): number {
    return this._clientPaysId;
  }
  public set clientPaysId(v: number) {
    this._clientPaysId = v;
  }
  
  private _employeeId!: number;
  public get employeeId(): number {
    return this._employeeId;
  }
  public set employeeId(v: number) {
    this._employeeId = v;
  }

  private _isOperationValid = true;
  public get isOperationValid(): boolean {
    return this._isOperationValid;
  }
  public set isOperationValid(v: boolean) {
    this._isOperationValid = v;
  }

  private _customGridFilterEnable = false;
  public get customGridFilterEnable(): boolean {
    return this._customGridFilterEnable;
  }
  public set customGridFilterEnable(v: boolean) {
    this._customGridFilterEnable = v;
  }

  public get initialQueryParams(): string {
    return this.storage.secureSessionStorage.getItem(StorageKey.InitialQueryParams);
  }
  public set initialQueryParams(initialQueryParams: string) {
    this.storage.secureSessionStorage.setItem(StorageKey.InitialQueryParams, initialQueryParams);
  }

  private _authenticatedUser!: AuthUser;
  public get authenticatedUser(): AuthUser {
    return this._authenticatedUser;
  }
  public set authenticatedUser(v: AuthUser) {
    this._authenticatedUser = v;
  }

  public resolvePath(path: any, obj: any){
    return path.split('.').reduce((prev: any, curr: any) => {
      return prev ? prev[curr] : null;
    }, obj || self);
  }

  getHeaderContentFilter(source: any): any {
    return  this.storage.secureLocalStorage.getItem(JSON.stringify(source + StorageKey.HeaderContentFilter));
  }
  setHeaderContentFilter(source: any, filter:  any) {
    this.storage.secureLocalStorage.setItem(JSON.stringify(source + StorageKey.HeaderContentFilter), filter);
  }


  sendMessage(messageAction: MessageAction, messageData: any = ''): void{
    this.subject$.next({MessageAction: messageAction, MessageData: messageData} as MessageBus);
  }

  messages$(): Observable<MessageBus> {
    return this.subject$;
  }

  resetMessages(): void {
    this.subject$.next(EMPTY);
  }

  ngOnDestroy(): void{
    this.subject$.complete();
  }


}
