import { Component } from '@angular/core';
import { DynControlBase } from '../dyn-controls-base';

@Component({
  selector: 'app-dyn-divider',
  templateUrl: './dyn-divider.component.html',
  styleUrls: ['./dyn-divider.component.scss', '../dyn-controls.scss']
})
export class DynDividerComponent extends DynControlBase {
}
