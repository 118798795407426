import { Component } from '@angular/core';
import { IntlService } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/fr-CA/all';

import { TranslateService } from '@ngx-translate/core';
import { BusService } from './shared/services/bus.service';
import { BaseEntity } from './modules/shell/models/BaseEntity.model';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'TinERP';
  menuLogoContainer = {'background-color': '#ededed'};

  public icon = 'arrow_back_ios';

  public toggleIcon(): void {
      if (this.icon === 'arrow_back_ios') {
          this.icon = 'arrow_forward_ios';
          this.menuLogoContainer =  {'background-color': '#f8f7f7'};
      } else {
          this.icon = 'arrow_back_ios';
          this.menuLogoContainer =  {'background-color': '#ededed'};

      }
  }

  constructor(
    private serviceBus: BusService,
    private translate: TranslateService,
    public intlService: IntlService,
    ) {
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang(this.serviceBus.currentLanguage);
      this.translate.use(this.serviceBus.currentLanguage);
      BaseEntity.currentLanguage = this.serviceBus.currentLanguage;
  }
}
