<mat-toolbar class="toolbar-header">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
      <div fxFlex class="m-l-20">
        <span class="m-l-10 font-14">{{data.title | translate}}</span>
      </div>
      <button mat-icon-button type="button" tabindex="-1"  class="mr-1" (click)="cancel()">
        <mat-icon>highlight_off</mat-icon>
      </button>
    </div>
</mat-toolbar>

<mat-toolbar class="toolbar-title">
    <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
      <div fxFlex class="m-l-20">
          <mat-icon>{{setupDynamicForm.iconname || 'business'}}</mat-icon>
        <span  class="m-l-10 font-16">{{data.headerText}}</span>
        <a class="link" *ngIf="data.headerExternalLink" (click)="navigateToLink()">
          {{data.headerText}}
          <mat-icon class="external-link">launch</mat-icon>
        </a>
      </div>
    </div>
</mat-toolbar>

<app-dynamic-form-build [setup]="setupDynamicForm" (formChange)="dynamicForm=$event"></app-dynamic-form-build>
