import { Observable } from 'rxjs';
import { InputMask } from '../../global';

export enum ControlFormatType {
    Date = 'date',
    SkeletonDate = 'skeletonDate',
}

export enum ControlType {
    TextBox = 'textbox',
    Textarea = 'textarea',
    DropDown = 'dropdown',
    TextLookup = 'textLookup',
    Switch = 'switch',
    Checkbox = 'checkbox',
    Datepicker = 'datepicker',
    Button = 'button',
    ButtonIcon = 'buttonIcon',
    FormGroup = 'formGroup',
    Action = 'action',
    Editor = 'editor',
    Divider = 'divider',
    Custom = 'custom',
    MultipleSelector = 'multipleSelector'
}

export interface ControlBaseConfig {
    keyName?: string;
    name?: string;
    order: number;
    mdWidth?: string; // e.g. 25, 50, 100
    lgWidth?: string; // e.g. 25, 50, 100
    readonly?: boolean;
    hideInNew?: boolean;
    hideInEdit?: boolean;
    hideBasedField?: ConfigField; 
    hideBasedContext?: ConfigField; 
    hideBasedFunc?: (formValues: any) => boolean; 
    showBasedField?: ConfigField; 
    showBasedContext?: ConfigField; 
    showBasedFuncContext?: ConfigFunc;
    showBasedFunc?:  (formValues: any) => boolean; 
    readonlyBasedContext?: ConfigField; 
    readonlyBasedField?: ConfigField; 
    readonlyBasedFunc?: (formValues: any) => boolean; 
    actionName?: string;
    translatePrefixLabel?: string;
    permission?: string | string[];
    style?: { [klass: string]: any; };
    flexAlign?: string;
    nameBasedFunc?: (formValues: any, ctx: any) => string; 
}

export interface AllControlConfig {
    readonly: boolean;
    permissionToChange?: string | any[]; // When defined, if the user does not have one of the permissions, the fields are readonly
    readonlyBasedField?: ConfigField; 
    readonlyBasedContext?: ConfigField;
}

export interface ControlDividerConfig extends ControlBaseConfig{
    text?: string
}

export interface ChildControl {
    type: ControlType;
    name?: string;
    labelTranslatePrefix?: string;
    actionName?: string;
    showOnlyParentIsReadOnly?: boolean;
    showOnlyParentHasValue?: boolean;
    hideBasedField?: ConfigField;
    className?: string;
    classIconName?: string;
    iconName?: string;
    topPosition?: string;
    hideParentIsReadOnly?:boolean;
}

export interface ControlInputAutoCompleteConfig {
    valueField: string;
    textField: string;
}
export interface ControlInputConfig extends ControlBaseConfig {
    typeInput?: string;
    format?: ControlFormat;
    childControl?: ChildControl;
    autocomplete?: ControlInputAutoCompleteConfig;
    placeholder?: string;
    prefix?: string;
    suffix?: string;
    translatePrefixSuffix?: boolean;
    className?: string;
    numberMin?: number;
    numberMax?: number;
    inputMask?: string | InputMask;
    isInputMaskRegex?: boolean;
    isInputMaskDateTime?: boolean;
    autoUnmask?: boolean;
    

}
export interface ControlTextareaConfig extends ControlBaseConfig {
    rows?: number;
    placeHolder?: string;
    className?: string;
}
export interface ControlDatePickerConfig extends ControlBaseConfig {
    min?: Date;
    max?: Date;
}
export interface ControlDropDownConfig extends ControlBaseConfig {
    translatePrefix?: string;
    translateItem?: boolean;
    contentIsIcon?: boolean;
    fieldNameValue?: string;
    fieldNameText?: string;
    fieldNameTextFunction?: (item: any) => string; // get text dynamically
    forceValueToGetObject?: boolean;
    objectFieldName?: string; //only when isMultiple is false
    loadItemsOnlyWhenClick?: boolean; //only when backend filter is not used
    childControl?: ChildControl;
    labelNewItem?: string;
    isMultiple?: boolean;
    useControlFormArray?: boolean;
    actionNewName?: string;
    labelRemoveItem?:string;
    fieldNamePreviousValue?: string;
    search?: ControlDropDownSearchConfig;
    forceChangeDetector?: boolean;
    afterMultiSelectionContextFuncName?: string; //only when isMultiple is true
    cleanValuesAfterMultiSelection?: boolean; //only when isMultiple is true
}
export interface ControlDropDownSearchConfig{
    filterFunction?: (value: any, items: any[]) => any; //only when backend filter is not used
    backendFilterContextFuncName?: string; // have to return Observable query
    placeHolder?: string;
    debounceTime?: number; //default 300ms
    minCharactersStartSearch?: number; //default 3
}
export interface ControlTextLookupConfig extends ControlBaseConfig {
    translatePrefix?: string;
    translateItem?: boolean;
}
export interface ControlButtonConfig extends ControlBaseConfig {
    actionType?: string;
    showAtBottom?: boolean;
    className?: string;
    onlyEnableWhenFormIsDirty?: boolean;
}
export interface ControlButtonIconConfig extends ControlButtonConfig{
    iconName?: string;
    noName?: boolean;
    classIconName?: string;
    tooltipMessage?: string;
    tooltipTranslatePrefix?: string;
    tooltipPosition?: 'left' | 'right' | 'above' | 'below' | 'before' | 'after';
    badgeIconName?: string;
    showBadgeIconBasedContext?: ConfigField;
}
export interface ControlFormGroupConfig {
    keyName?: string;
    order: number;
    mdWidth?: string;
}
export interface ControlFormat {
    type: ControlFormatType;
    pattern: string;
}
export interface ControlEditorConfig extends ControlBaseConfig {
    id?: number;
}
export interface ControlCustomConfig extends ControlBaseConfig{
    className?: string;
    templateContextName: string;
}
export interface SetupDynamicForms {
    formTitle?: string;
    recreateForm?: boolean;
    controls: any[];
    modelToInitForm: any;
    defaultValuesToInitForm?: any;
    lookups: null | ConfigObjectKey;
    dropdowns: null | ConfigObjectKey;
    context: any;
    disableStyle?: boolean;
    //actions: (actionName: string, modelInstance?: any | null, event?: any | null) => any;
    iconname?:string;
  }

export interface ConfigObjectKey {
    [key: string]: Observable<any>;
}

export interface ConfigField {
    name: string
    value: any
}

export interface ConfigFunc {
    functionName: string // Example: (parms: any) => boolean
    params: any
}



