import { AfterContentChecked, Component } from '@angular/core';
import { DynControlBase } from '../dyn-controls-base';


@Component({
  selector: 'app-dyn-text-area',
  templateUrl: './dyn-text-area.component.html',
  styleUrls: ['./dyn-text-area.component.scss', '../dyn-controls.scss']
})

export class DynTextAreaComponent extends DynControlBase implements AfterContentChecked {

  ngAfterContentChecked(): void {
    this.afterContentChecked()
  }

}
