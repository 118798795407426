//Les méthodes d'extension doivent être utilisées à des fins spécifiques avec modération et 
// sans affecter les paramètres d'entrée dans les futures versions de prototypes.
export{} 
declare global {
  interface String {
    normalizeToLowerCase(this: string): string;
    toDate(this: string): Date;
    replaceFilterParams(this: string, params: any[]): string;
  }
}

String.prototype.normalizeToLowerCase = function (this: string): string {
  //https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
  return this.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
};

String.prototype.toDate = function (this: string): Date {
  return new Date(this);
};

String.prototype.replaceFilterParams = function(params: any[]): string {
  let result = this;
  if (params?.length === 1) {
    result = this.split('#').join(params[0]);
    } else if (params?.length > 1) {
      for (let i = 0; i < params.length; i++) {
        const p = params[i];
        result = result.replace(`#${i + 1}`, p);
      }
    }
  return result;
}