import { AfterContentChecked, Component, OnInit } from '@angular/core';
import { DynControlBase } from '../dyn-controls-base';

@Component({
  selector: 'app-dyn-custom',
  templateUrl: './dyn-custom.component.html',
  styleUrls: ['./dyn-custom.component.scss', '../dyn-controls.scss']
})
export class DynCustomComponent extends DynControlBase implements OnInit, AfterContentChecked  {
  public template: any;

  ngAfterContentChecked(): void {
    this.afterContentChecked()
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.template = this.context[this.control.templateContextName];
    }, 0);
  }
}
