import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RxFormGroup } from '@rxweb/reactive-form-validators';
import { SetupDynamicForms } from '../../dynamic-forms/controls/control-config';


@Component({
  selector: 'tin-dialog-entity-details',
  templateUrl: './tin-dialog-entity-details.component.html',
  styleUrls: ['./tin-dialog-entity-details.component.scss']
})
export class TinDialogEntityDetailsComponent implements OnInit {

  public setupDynamicForm!: SetupDynamicForms;
  public dynamicForm!: RxFormGroup;

  constructor(
    private dialogRef: MatDialogRef<TinDialogEntityDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.setupDynamicForm = this.data.setupForm;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  navigateToLink() {
 window.open(this.data.headerExternalLink, "_blank");
  }
}
