import { BrowserModule } from '@angular/platform-browser';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppComponent } from './app.component';
import { MainComponent } from './layout/main.component';
import { HeaderComponent } from './layout/header/header.component';
import { TinSpinnerComponent } from './shared/components/tin-spinner/tin-spinner.component';
import { TinSpinnerInterceptor } from './shared/components/tin-spinner/tin-spinner.interceptor';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { BreadcrumbComponent } from './layout/breadcrumb/breadcrumb.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { HttpErrorHandler } from './shared/services/http-error-handler.service';
import { TinMessageService } from './shared/services/tin-message.service';
import { AuthInterceptor } from './auth/auth-interceptor';
import {TranslateLoader, TranslateModule, TranslateStore} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { setAppInjector } from './shared/dynamic-forms/decorators/app-injector';
import { LoginComponent } from './auth/login/login.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HttpValidationInterceptor } from './shared/services/http-validation-interceptor';
import { SharedModule } from './shared/shared.module';
import { registerLocaleData } from '@angular/common';
import localeFrCA from '@angular/common/locales/fr-CA';
import { BusService } from './shared/services/bus.service';
import { Language } from './shared/global';

registerLocaleData(localeFrCA);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    TinSpinnerComponent,
    MainComponent,
    SidebarComponent,
    BreadcrumbComponent,
    CallbackComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    NgxPermissionsModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'fr',
      extend: true
    }),
  ],
  providers: [
    HttpErrorHandler,
    TinMessageService,
    TranslateStore,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: HttpValidationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TinSpinnerInterceptor, multi: true },
    { provide: LOCALE_ID, deps: [BusService], 
      useFactory: (serviceBus: BusService) => serviceBus.currentLanguage === Language.English ? 'en-US' : 'fr-CA'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
