import { Input } from '@angular/core';
import { ErrorStateMatcher } from '@angular/material/core';
import { ChildControl, ControlBaseConfig } from './control-config';
import { DynControlBaseDirective } from './dyn-control-base.directive';

export class ControlErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: any, formDirective: any): boolean {
    return control ? isError(control, formDirective.form) : false;
  }
}

export  abstract class DynControlBase extends DynControlBaseDirective {
  errorState = new ControlErrorStateMatcher();
  hasControlError = false;
  isError = isError;

  public get formGroup(): any {
    return this.form.get(this.control?.keyName);
  }

  isReadOnly(): boolean {
    let result = false;
    if (this.control && this.context && this.form) {
      result = this.control.readonly || this.control.parent?.readonly ||

      (this.control.readonlyBasedField?.name !== '-' && 
      this.form.get(this.control.readonlyBasedField?.name)?.value === this.control.readonlyBasedField?.value) || 

      (this.control.parent?.readonlyBasedField?.name !== undefined && this.control.parent?.readonlyBasedField?.name !== '-' 
        && this.form.get(this.control.parent?.readonlyBasedField?.name)?.value === this.control.parent?.readonlyBasedField?.value) || 

      (this.context[this.control.readonlyBasedContext?.name] !== undefined  && this.control.readonlyBasedContext?.name !== '-' 
        && this.context[this.control.readonlyBasedContext?.name] === this.control.readonlyBasedContext?.value) ||

      (this.context[this.control.parent?.readonlyBasedContext?.name] !== undefined  && this.control.parent?.readonlyBasedContext?.name !== '-'
        && this.context[this.control.parent?.readonlyBasedContext?.name] === this.control.parent?.readonlyBasedContext?.value) ||

      (this.control?.readonlyBasedFunc !== undefined && this.control.readonlyBasedFunc(this.form.modelInstance));
    }
    return result;
  }
  
  afterContentChecked(chipList?: any): void {
    const hasError = isError(this.control, this.form)
    if (hasError !== undefined && this.hasControlError !== hasError) {
      this.dynamicFormsService.notifyControlErrors(this.control?.root?.modelName, this.control?.keyName, hasError,);
      this.hasControlError = hasError;
      if (chipList) {
        chipList.errorState = hasError;
      }
    }
  }

  callFunction() {
    if (this.control?.actionName  && this.context && this.context[this.control?.actionName] && 
      typeof this.context[this.control.actionName] === 'function') {
        this.context[this.control.actionName](this.formGroup, this.form, this.formArrayIndex);
      }
  }
  callButtonFunction(controlParent?: any) {
    if (this.control?.actionName  && this.context && this.context[this.control?.actionName] && 
      typeof this.context[this.control.actionName] === 'function') {
        const controlParentFormGroup = controlParent ? this.form.get(controlParent?.keyName) : null;
        if (controlParentFormGroup) {
          this.context[this.control.actionName](controlParentFormGroup, this.form, this.formArrayIndex);
        } else {
          this.context[this.control.actionName](this.form, this.formArrayIndex);
        }
      }
  }
  callCustomFunction(actionName: string, param?: any) {
    if (actionName  && this.context && this.context[actionName] && 
      typeof this.context[actionName] === 'function') {
        this.context[actionName]((param ? param : this.formGroup), this.form, this.formArrayIndex);
      }
  }

  getControlId() {
    if (this.formArrayIndex) {
      return `${this.formArrayIndex}'_'${this.control?.root?.modelName}_${this.control?.keyName}`;
    } else {
      return `${this.control?.root?.modelName}_${this.control?.keyName}`;
    }

  }

  getName() {
    if (this.control) {
      if (this.control.nameBasedFunc !== undefined && typeof this.control.nameBasedFunc === 'function') {
        return this.control.nameBasedFunc(this.form?.modelInstance, this.context);
      } else {
        return this.control.name;
      }
    } else {
      return '';
    }
  }

  showChildControl(childControl: ChildControl, parentControl: any):boolean{
    let result = false;
    if (childControl && childControl?.type === 'buttonIcon' && parentControl) {
      result =
      (childControl.showOnlyParentIsReadOnly && parentControl.readonly) ||
      (childControl.hideBasedField?.name === '-' && childControl.showOnlyParentHasValue === false) ||
      (childControl.showOnlyParentHasValue && this.form.get(parentControl.keyName)?.value !== undefined && this.form.get(parentControl.keyName)?.value !== null) || 
      (childControl.hideBasedField?.name !== '-' && !(this.form.get(childControl.hideBasedField?.name)?.value === childControl.hideBasedField?.value))
    }

    if(childControl?.hideParentIsReadOnly && this.isReadOnly()){
      result = false;
    }
    return result;
  }
}

export function isError(control: any, form: any): boolean {
  return (control && form && ((form.get(control?.keyName)?.errors && form.submitted) || 
    (form.get(control.keyName)?.errors?.async?.message ? true : false)));
}  