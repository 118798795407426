import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

// @ts-ignore
import SecureStorage from 'secure-web-storage';

const SECRET_KEY = environment.storageSecretKey;

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  constructor() {}

  public secureSessionStorage = new SecureStorage(sessionStorage, {
    hash: this.hash,
    encrypt: this.encrypt,
    decrypt: this.decrypt
  });
  public secureLocalStorage = new SecureStorage(localStorage, {
    hash: this.hash,
    encrypt: this.encrypt,
    decrypt: this.decrypt
  });
  hash(key: any): string {
      key = CryptoJS.SHA256(key, SECRET_KEY as any);
      return key.toString();
  }
  encrypt(data: any): string {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY);
    data = data.toString();
    return data;
  }
  decrypt(data: any): string {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY);
    data = data.toString(CryptoJS.enc.Utf8);
    return data;
  }

}
