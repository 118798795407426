<div class="dyn-form-component tin-input" [formGroup]="form">
    <div [class.error-space]="errorState.isErrorState(control,form)">
        <mat-form-field 
        [ngStyle]="control.style || false"
        appearance="{{control.appearance ? control.appearance : 'outline'}}">
            <mat-label>{{control.translatePrefixLabel + "." + getName() | translate}}
                <span *ngIf="formGroup?.invalid" class="required">*</span>
            </mat-label>
            <input *ngIf="inputMask"
            matInput [formControlName]="control?.keyName" 
            [ngClass]="{'input-currency': control.typeInput === 'currency', 'input-number': control.typeInput === 'number'}"
            (focus)="control?.typeInput === 'currency' ? $event.target.select() : false"
            (change)="callFunction()"
            [errorStateMatcher]="errorState"
            [readonly]="isReadOnly()"
            [inputMask]="inputMask"
            type="{{control.typeInput === 'currency' ? 'number' : control.typeInput }}"
            min="{{control.numberMin}}"
            [matAutocomplete]="auto"
            placeholder="{{control.placeHolder ? (control.translatePrefixLabel + '.' + control.placeHolder | translate) : ''}}"
            id="{{getControlId()}}"
            >
            <!-- Le masque ne fonctionne pas correctement avec autocomplete-->
            <input *ngIf="!inputMask"
            matInput [formControlName]="control?.keyName" 
            [ngClass]="{'input-currency': control.typeInput === 'currency', 'input-number': control.typeInput === 'number'}"
            (focus)="control?.typeInput === 'currency' ? $event.target.select() : false"
            (change)="callFunction()"
            [errorStateMatcher]="errorState"
            [readonly]="isReadOnly()"
            type="{{control.typeInput === 'currency' ? 'number' : control.typeInput }}"
            min="{{control.numberMin}}"
            [matAutocomplete]="auto"
            placeholder="{{control.placeHolder ? (control.translatePrefixLabel + '.' + control.placeHolder | translate) : ''}}"
            id="{{getControlId()}}"
            >
            <span *ngIf="control.prefix && formGroup?.value !== null && formGroup?.value !== ''" matPrefix>{{control.translatePrefixSuffix ? (control.prefix | translate) : control.prefix}}</span>
            <span *ngIf="control.suffix" matSuffix>{{control.translatePrefixSuffix ? (control.suffix | translate) : control.suffix}}</span>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let item of autocompleteItems | async" [value]="item[control?.autocomplete?.valueField]">                    
                  {{item[control?.autocomplete?.textField]}}
               </mat-option>
           </mat-autocomplete>
           <mat-error class="form-text text-danger" *ngIf="hasControlError">{{inputMask ? formGroup?.errorMessages?.[0] : formGroup?.errorMessage}}</mat-error>
        </mat-form-field>
        <div 
        [ngStyle]="{'position': 'relative', 'top': control.childControl.topPosition || '-10px'}"
        *ngIf="showChildControl(control.childControl, control)">
            <app-dyn-button-icon [form]="form" [control]="control.childControl" [controlParent]="form.get(this.control?.keyName)"  
            [context]="context" [formArrayIndex]="formArrayIndex"></app-dyn-button-icon>
        </div>
    </div>
</div>
