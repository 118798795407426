import { NgModule } from '@angular/core';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { KendoTranslateService } from './kendo-translate.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule, MaskedTextBoxModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IntlModule } from "@progress/kendo-angular-intl";
import { DragAndDropModule } from '@progress/kendo-angular-utils';
import { SplitterModule } from '@progress/kendo-angular-layout';
import { SparklineModule } from '@progress/kendo-angular-charts';
import { IconsModule } from '@progress/kendo-angular-icons';
import { NavigationModule } from '@progress/kendo-angular-navigation';

import 'hammerjs';

@NgModule({
   exports: [
    GridModule,
    PDFModule,
    ExcelModule,
    DateInputsModule,
    InputsModule,
    LabelModule,
    DropDownsModule,
    ButtonsModule,
    MaskedTextBoxModule,
    IntlModule,
    DragAndDropModule,
    SplitterModule,
    SparklineModule,
    IconsModule,
    NavigationModule
  ],
  providers: [
    { provide: MessageService, useClass: KendoTranslateService }
  ]
})
export class KendoModule { }
