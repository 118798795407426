<mat-card class="card-control">
    <mat-card-content class="card-content-control">
        <div [formGroup]="form" >
            <mat-slide-toggle 
            [ngStyle]="control.style || false"
            id="{{getControlId()}}"
            [disabled] = "isReadOnly()"
            (change)="callCustomFunction(control.actionName, $event)" 
            formControlName="{{control.keyName}}">{{control.translatePrefixLabel + '.' + getName() | translate}}
                <span *ngIf = "formGroup?.invalid" class="required">*</span> 
            </mat-slide-toggle>
        </div>
    </mat-card-content>
</mat-card>



