<mat-toolbar class="no-shadow"  [ngClass]="{'mobile': isMobile}">
  <button class="mr-1" mat-icon-button *ngIf="setup.showExpandButton" (click)="toggleExpandDetails()">
      <mat-icon>{{ isExpanded ? 'fullscreen_exit' : 'fullscreen'}}</mat-icon>
  </button>
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <div fxFlex class="m-l-20">
      <span class="m-l-10 font-14">{{headerTitle | translate}} {{headerTitleSuffix}}</span>
    </div>
     <div *ngxPermissionsOnly="permissions?.write">
      
      <button  *ngIf="setup.showOkButton || setup.showSaveAndCloseButton" mat-raised-button color="accent" class="m-r-20"
        [disabled]="setup.okButtonIsDisabled" (click)="ok()">
          {{(setup.showSaveAndCloseButton ? 'global.saveAndClose' : 'global.confirm') | translate}}
      </button>

      <button *ngIf="setup.showCancelButton" mat-raised-button color="cancel" class="m-r-20" 
        [disabled]="setup.cancelButtonIsDisabled" (click)="cancel()">
          {{'global.cancel' | translate}}
      </button>
    </div>

    <div *ngxPermissionsOnly="permissions?.delete">
      <button *ngIf="setup.showDeleteButton" mat-raised-button color="warn" class="m-r-20"
        [disabled]="isButtonDeleteDisabled" (click)="delete()">
          {{'global.delete' | translate}}
      </button>
    </div>
    <button mat-icon-button  class="mr-1" (click)="close()">
      <mat-icon>highlight_off</mat-icon>
    </button>
  </div>
</mat-toolbar>