import { Injectable } from '@angular/core';
import { findIndex } from 'lodash';
import { AuthService } from 'src/app/auth/auth.service';

import { Module } from '../global';
import { AdminMenuItems, ClientMenuItems, EmployeMenuItems, Menu, DefaultMenuItems, DemandeCueilletteMenuItems, DispatchMenuItems, FormationMenuItems } from './menu-items';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private groups!: string[];

  constructor(
    private authService: AuthService
  ) {
    this.loadPermissions();
  }


  getMenuItems(module: Module): Menu[] {
    switch (module) {
    case Module.Client:
      return this.getAuthorizedMenus(ClientMenuItems);
    case Module.Employe:
      return this.getAuthorizedMenus(EmployeMenuItems);
    case Module.Admin:
      return this.getAuthorizedMenus(AdminMenuItems);
    case Module.DemandeCueillette:
      return this.getAuthorizedMenus(DemandeCueilletteMenuItems);
    case Module.Dispatch:
      return this.getAuthorizedMenus(DispatchMenuItems);
    case Module.Formation:
        return this.getAuthorizedMenus(FormationMenuItems);      
    default:
      return this.getAuthorizedMenus(DefaultMenuItems);
    }
  }

  private async loadPermissions(): Promise<void> {
    this.groups = await this.authService.getPermissions();
  }

  private getAuthorizedMenus(menu: Menu[]): Menu[] {
    return menu.filter(x => x.permissions?.find(y => this.groups.find(z => z.toLowerCase() === y.toLowerCase())));
  }
}
