<div [formGroup]="form" >
    <div class="wrap" [ngClass]="{'errorField': hasControlError}">
        <mat-label class="custom-mat-label" *ngIf="control.name !== control.keyName" id="label">{{control.labelTranslatePrefix + "." + getName() | translate}} 
            <span *ngIf="formGroup?.invalid" class="required">*</span> 
        </mat-label> 
        <div [ngClass]="control.className === '' ? 'custom' : control.className">
            <ng-container [ngTemplateOutlet] = "template"></ng-container>
        </div>
        <span style="top:0px" class="errorLabel" *ngIf="hasControlError">{{formGroup?.errors?.async?.message || formGroup?.errorMessage}}</span>
    </div>
</div>
