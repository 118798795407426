import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { BusService } from '../shared/services/bus.service';
import { LibAuthService } from 'ngx-mat-auth';

@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(
    private auth: LibAuthService,
    private authService: AuthService,
    private bus: BusService
  ) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    if (route.data['module'] && route.data['module'] !== '') {
      this.bus.module =  route.data['module'];
    }
    if (await this.auth.isUserLoggedIn()) {
      if (await this.authService.hasAccessModuleAdmin()) {
        await this.authService.loadPermissions();
        this.authService.authorizedUser();
        return true;
      } else {
        this.authService.unauthorizedUser();
        return false;
      }
    } else {
      this.auth.federatedSignIn();
      return false;
    }
  }

}
