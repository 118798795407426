<div [formGroup]="form" class="dyn-form-component">
  <div class="no-error-space">
    <mat-form-field appearance="{{control.appearance ? control.appearance : 'outline'}}"
    [ngStyle]="control.style || false">
        <mat-label>{{control.translatePrefixLabel + "." + getName() | translate}}
          <span *ngIf = "form.get(control.keyName)?.invalid" class="required">*</span>
        </mat-label>

        <ng-container *ngIf="control.isMultiple">
          <mat-select #select disableRipple multiple 
          id="{{getControlId()}}"
          (openedChange)="openChanged($event, select.value)"
          (selectionChange)="selectionChange(select.value); callFunction();"
          [panelClass]="(isLoading || !isOpen) && control.loadItemsOnlyWhenClick ? 'hide-deferred-select-dummy-option' : ''" 
          [disabled]="isReadOnly()"
          formControlName="{{control.keyName}}" 
          [errorStateMatcher]="errorState">
              <mat-select-trigger *ngIf="control.contentIsIcon">
                <mat-icon>{{formGroup.value}}</mat-icon>
              </mat-select-trigger>

              <div class="dyn-form-component" *ngIf="control.search"> 
                <mat-form-field type="text" appearance="outline" 
                [ngClass]="{'back-search-input-field': isSearchBackendEnabled && control.isMultiple, 'front-search-input-field': !(isSearchBackendEnabled && control.isMultiple)}">
                  <input #searchInput matInput 
                  [formControl]="filterCtrl" 
                  placeholder="{{(control.search?.placeHolder ? (control.translatePrefixLabel + '.' + control.search?.placeHolder) : 'global.typeSearch') | translate}}">
                  <button class="refresh-button" mat-icon-button *ngIf="isSearchBackendEnabled && control.isMultiple" (click)="callDynamicRequest(searchInput.value)">
                    <mat-icon>refresh</mat-icon>
                </button>
                </mat-form-field>
              </div>

            <mat-option *ngIf="control.loadItemsOnlyWhenClick && (items === null || !items?.length)" disabled></mat-option>
            <mat-option *ngFor="let item of filteredItems" [value]="getValue(item)">
              <ng-container *ngIf="!control.contentIsIcon"> {{control.translateItem ? ((control.translatePrefix + '.' + getDescription(item)) | translate) : getDescription(item)}}</ng-container>
              <ng-container *ngIf="control.contentIsIcon"><mat-icon>{{item}}</mat-icon></ng-container>
            </mat-option>

            <mat-divider style="padding-top: 10px; padding-bottom: 10px;"></mat-divider>
            <mat-option *ngFor="let item of selectedItems" [value]="getValue(item)">
              <ng-container *ngIf="!control.contentIsIcon"> {{control.translateItem ? ((control.translatePrefix + '.' + getDescription(item)) | translate) : getDescription(item)}}</ng-container>
            </mat-option>
            <mat-option class="empty-select" *ngIf="control.search && !control.labelNewItem" ></mat-option>
            <mat-option class="new-select"  *ngIf="control.labelNewItem" (click)="newItem(); select.close() ">
              <mat-icon class="icon-new">add</mat-icon>{{control.translatePrefixLabel + "." + control.labelNewItem | translate}}
            </mat-option>
          </mat-select>
        </ng-container>


        <ng-container *ngIf="!control.isMultiple">
          <mat-select #select disableRipple
          id="{{getControlId()}}"
          (openedChange)="openChanged($event, select.value)"
          (selectionChange)="selectionChange(select.value); callFunction();"
          placeholder="{{control.loadItemsOnlyWhenClick ? ('global.loading' | translate) : ''}}"
          [panelClass]="(isLoading || !isOpen) && control.loadItemsOnlyWhenClick ? 'hide-deferred-select-dummy-option' : ''" 
          [disabled]="isReadOnly()"
          formControlName="{{control.keyName}}" 
          [errorStateMatcher]="errorState">
            <mat-select-trigger *ngIf="control.contentIsIcon">
              <mat-icon>{{formGroup.value}}</mat-icon>
            </mat-select-trigger>

            <div class="dyn-form-component" *ngIf="control.search"> 
              <mat-form-field type="text" appearance="outline" 
              [ngClass]="{'back-search-input-field': isSearchBackendEnabled && control.isMultiple, 'front-search-input-field': !(isSearchBackendEnabled && control.isMultiple)}">
                <input #searchInput matInput 
                [formControl]="filterCtrl" 
                placeholder="{{(control.search?.placeHolder ? (control.translatePrefixLabel + '.' + control.search?.placeHolder) : 'global.typeSearch') | translate}}">
                <button class="refresh-button" mat-icon-button *ngIf="isSearchBackendEnabled && control.isMultiple" (click)="callDynamicRequest(searchInput.value)">
                  <mat-icon>refresh</mat-icon>
              </button>
              </mat-form-field>
            </div>

            <mat-option *ngIf="control.loadItemsOnlyWhenClick && (items === null || !items?.length)" disabled></mat-option>
         
            <mat-option *ngFor="let item of filteredItems" [value]="getValue(item)">
              <ng-container *ngIf="!control.contentIsIcon"> {{control.translateItem ? ((control.translatePrefix + '.' + getDescription(item)) | translate) : getDescription(item)}}</ng-container>
              <ng-container *ngIf="control.contentIsIcon"><mat-icon>{{item}}</mat-icon></ng-container>
            </mat-option>
            <mat-option  *ngIf="control.search && !control.labelNewItem">
              <span *ngIf="control.labelRemoveItem"><mat-icon class="icon-new">clear</mat-icon>{{control.translatePrefixLabel + "." + control.labelRemoveItem | translate}}</span>
            </mat-option>
            <mat-option class="new-select" *ngIf="control.labelNewItem" (click)="newItem(); select.close()">
              <mat-icon class="icon-new">add</mat-icon>{{control.translatePrefixLabel + "." + control.labelNewItem | translate}}
            </mat-option>
          </mat-select>
        </ng-container>

        <mat-spinner *ngIf="isLoading" class="internal-spinner" diameter="20"></mat-spinner>  
        <mat-error class="form-text text-danger" *ngIf="errorState">{{form.get(control.keyName)?.errorMessage}}</mat-error>
      </mat-form-field>

      <div *ngIf="showChildControl(control.childControl, control)"
      [ngStyle]="{'position': 'relative', 'top': control.childControl.topPosition || '-10px'}"> 
         <app-dyn-button-icon [form]="form" [control]="control.childControl" [controlParent]="form.get(this.control?.keyName)"  
         [context]="context" [formArrayIndex] = "formArrayIndex"></app-dyn-button-icon>
      </div>

  </div>
</div>