<div [formGroup]="form"  class="form">
    <button mat-raised-button
    id="{{getControlId()}}"
    type="{{control.actionType}} || 'button'"
    class="{{'button ' + control.className}}"
    style="height: 36px;"
    [disabled]="isReadOnly() || (control.onlyEnableWhenFormIsDirty && !form.dirty)"
    (click)="callButtonFunction()">{{control.translatePrefixLabel + '.' + getName() | translate}}</button>
</div>

