
  <div mat-dialog-title>
    <app-header-dialog [setup]="setupHeaderDialog" 
    [headerTitle]="data?.title" 
    [headerTitleSuffix]="data?.titleSuffix"
    [permissions]="data?.permissions"
    ></app-header-dialog>
  </div>
  
   <mat-dialog-content>
      <ng-template #container></ng-template>
  </mat-dialog-content >


  
  
  
  

