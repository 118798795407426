import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { LibAuthService } from 'ngx-mat-auth';
import { BusService } from '../shared/services/bus.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private auth: LibAuthService,
    private serviceBus: BusService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return from(this.handle(req, next));
  }

  async handle(req: HttpRequest<any>, next: HttpHandler): Promise<any> {
    if (!req.url.includes('/assets/')) {
      const accessToken = await this.auth.getAccessToken();
      const authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Language": this.serviceBus.currentLanguage       }
      });
      return next.handle(authReq).toPromise();
    }
    else {
      return next.handle(req).toPromise();
    }
  }
}
