import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { RxFormBuilder, RxFormGroup } from '@rxweb/reactive-form-validators';
import {  Subscription } from 'rxjs';
import { ControlType, SetupDynamicForms } from '../controls/control-config';
import { DynamicFormsService } from '../dynamic-forms.service';

@Component({
  selector: 'app-dynamic-form-build',
  templateUrl: './dynamic-form-build.component.html',
  styleUrls: ['./dynamic-form-build.component.scss']
})
export class DynamicFormBuildComponent implements OnInit, OnDestroy, OnChanges {
  @Input() setup!: SetupDynamicForms;
  @Input() formInArray!: RxFormGroup;
  @Input() formArrayIndex!: number;
  @Input() isFormArray = false;
  @Input() showForm = true;
  @Output() formChange = new EventEmitter<RxFormGroup>();

  public form!: RxFormGroup;
  public buttonsActions: any = [];

  private subscriptions = new Subscription();

  constructor(
    private fb: RxFormBuilder,
    private dynamicFormsService: DynamicFormsService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formInArray) {
      this.form = this.formInArray; 
    }
  }
  ngOnInit(): void {
    if (!this.isFormArray) {
      const modelName = this.setup?.modelToInitForm?.name || '';
      const instanceForm = this.dynamicFormsService.getInstanceForm(modelName);
      if (!instanceForm) {
        let startValues: any;
        if (this.setup.defaultValuesToInitForm) {
          startValues = new this.setup.modelToInitForm(this.setup.defaultValuesToInitForm);
        } else {
          startValues = new this.setup.modelToInitForm();
        }
        this.form = this.fb.formGroup(startValues) as RxFormGroup;
        this.dynamicFormsService.setInstanceForm(modelName, this.form);
      } else {
        this.form = instanceForm;
      }

      this.formChange.emit(this.form);

      this.subscriptions.add(this.dynamicFormsService.listenForFormChanges(modelName).subscribe((form: any) => {
        if (form) {
          this.form = form;
          this.formChange.emit(this.form);
        }
      }));

    } else if(this.formInArray) {
        this.form = this.formInArray; 
    }

    this.buttonsActions = this.setup.controls.filter(x => (x.type === ControlType.Button || x.type === ControlType.ButtonIcon)
    && x.showAtBottom);
  }

  getControls(controlForm: RxFormGroup): any[] {
    const result = this.setup.controls.filter(ctrl => this.showControl(ctrl, controlForm));
    return result;
  }
 
  showControl(control: any, controlForm: RxFormGroup): boolean {
    let result = false;
    if (control) {
      result =
      (control.showBasedField?.name === '-' && control.hideBasedField?.name === '-' && control.showBasedContext?.name === '-' && 
        control.hideBasedContext?.name === '-' && this.setup.context[control.showBasedFuncContext?.functionName] == undefined 
        && control?.showBasedFunc === undefined && control?.hideBasedFunc === undefined ) ||

      (controlForm.get(control.showBasedField?.name)?.value === control.showBasedField?.value) || 

      (control?.showBasedFunc !== undefined && control.showBasedFunc(controlForm.modelInstance)) || 
      
      (control?.hideBasedFunc !== undefined && !(control.hideBasedFunc(controlForm.modelInstance))) || 

      (this.setup.context[control.showBasedContext?.name] !== undefined && this.setup.context[control.showBasedContext?.name] === control.showBasedContext?.value) || 

      (this.setup.context[control.showBasedFuncContext?.functionName] !== undefined && 
        this.setup.context[control.showBasedFuncContext?.functionName].call(this.setup.context, control.showBasedFuncContext?.params, control.keyName, controlForm)) || 

      (control.hideBasedField?.name !== '-' && !(controlForm.get(control.hideBasedField?.name)?.value === control.hideBasedField?.value)) ||

      (control.hideBasedContext?.name !== '-' && !(this.setup.context.hasOwnProperty(control.hideBasedContext?.name) && 
        this.setup.context[control.hideBasedContext?.name] === control.hideBasedContext?.value))
    }
    return result;
  }

  getMdWidth(control: any): string {
    return control.mdWidth;
  }

  getLgWidth(control: any): string {
    return control.lgWidth ?? this.getMdWidth(control);
  }

  getFlexAlign(control: any): string {
    if (control.flexAlign) {
      return control.flexAlign;
    }
    switch (control.type) {
    case ControlType.Switch:
    case ControlType.Button:
    case ControlType.ButtonIcon:
      return 'center';
    default:
      return 'start';
    }
  }

  readonlyForm() {
    return this.setup.controls.filter(x => x.keyName === 'all')[0]?.readonly;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.dynamicFormsService.dispose(this.setup?.modelToInitForm?.name);
  }

}
