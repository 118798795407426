import { Component, OnInit} from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-notauthorized',
  templateUrl: './not-authorized.component.html'
})
export class NotAuthorizedComponent  implements OnInit {
  constructor(
    private authService: AuthService,
    ){
    
  }
  async ngOnInit(): Promise<void> {
    this.authService.unauthorizedUser();
  }
}
