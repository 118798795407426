 <mat-toolbar class="toolbarHeader" [ngClass]="{'mobile': isMobile}">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
    <div fxFlex class="m-l-20">
      <span class="m-l-10 font-18">{{data.title | translate}}</span>
    </div>
    <button mat-icon-button type="button" tabindex="-1"  class="mr-1" (click)="no()">
      <mat-icon>highlight_off</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-card style="margin: 10px; display: contents;" >
    <mat-card-content style="padding-top: 10px;">
      <div  *ngIf="!data.isHtml"  style="padding: 10px;">
        <span>{{data.message | translate}}</span>
      </div>
      <div *ngIf="data.isHtml" style="padding: 10px;" [innerHtml]="data.message | nl2br" ></div>

      <div class="form-field-textarea">
        <mat-form-field *ngIf="data.showTextArea" appearance="outline">
          <mat-label class="label-textarea">{{data.textAreaTitle}}</mat-label>
          <textarea matInput 
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            (change)="textareaChange($event)"
            [attr.maxlength]="data.textareaMaxLength"
          >
          </textarea>
        </mat-form-field>
      </div>

      <mat-card-actions *ngIf="data.buttonOk" class="actions">
          <button mat-raised-button type="button" color="primary" class="m-r-20" (click)="ok()" 
            cdkFocusInitial>
             {{'global.ok' | translate}}
          </button>
      </mat-card-actions>

      <mat-card-actions *ngIf="data.buttonVisible" class="actions">
          <button mat-raised-button type="button" color="warn" class="m-r-20" (click)="yes()">
               <mat-icon>delete</mat-icon>{{'global.yes' | translate}}
              
          </button>
          <button mat-raised-button type="button" color="primary" class="m-r-20" (click)="no()" 
            cdkFocusInitial>
             {{'global.no' | translate}}
          </button>
      </mat-card-actions>

      <mat-card-actions *ngIf="data.notDeleting" class="actions">
        <button *ngIf="!data.buttonYesSave" mat-raised-button type="button" color="primary" class="m-r-20" (click)="yes()">
          <mat-icon>check</mat-icon>{{'global.yes' | translate}}
        </button>
        <button *ngIf="data.buttonYesSave" mat-raised-button type="button" color="accent" class="m-r-20" (click)="yes()">
          <mat-icon>save</mat-icon>{{'global.save' | translate}}
        </button>
        <button mat-raised-button type="button" color="warn" class="m-r-20" (click)="no()" 
          cdkFocusInitial>
           {{'global.no' | translate}}
        </button>
      </mat-card-actions>

      <mat-card-actions *ngIf="data.buttonChangesVisible" class="actions">
        <button mat-raised-button color="accent" class="m-r-20" (click)="save()">
            <mat-icon>save</mat-icon> {{'global.save' | translate}}
        </button>
        <button mat-raised-button color="cancel" class="m-r-20" (click)="cancel()">
            <mat-icon>undo</mat-icon> {{'global.cancel' | translate}}
        </button>

      </mat-card-actions>

      <mat-card-actions *ngIf="data.pickupSaving" class="actions">
        <button mat-raised-button type="button" color="accent" class="m-r-20" (click)="yes()"><mat-icon>save</mat-icon>{{'global.save' | translate}}</button>
        <button mat-raised-button type="button" color="accent" class="m-r-20" (click)="saveAndSend()"><mat-icon>save</mat-icon>{{'global.saveAndSend' | translate}}</button>
        <button mat-raised-button color="cancel" class="m-r-20" (click)="no()"><mat-icon>undo</mat-icon> {{'global.cancel' | translate}}</button>
      </mat-card-actions>
    </mat-card-content>
</mat-card> 
