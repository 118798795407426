import { BusService } from './bus.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TinMessageService } from './tin-message.service';

@Injectable({
  providedIn: 'root'
})
export class HttpValidationInterceptor implements HttpInterceptor{

  constructor(
    private injector: Injector
  ) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   return next.handle(req).pipe(
     tap(response => {
       const resp = response as HttpResponse<any>;
       if (resp && resp.body && resp.status === 200 && resp.body.validationCode && resp.body.validationCode !== '' )
       {
         const messageService = this.injector.get(TinMessageService);
         const serviceBus = this.injector.get(BusService);
         serviceBus.isOperationValid = false;
         messageService.showValidationMessage(resp.body.validationCode);
       }
     })
   );
  }
}
