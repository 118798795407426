import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TinSpinnerService } from './tin.spinner-service';

@Injectable()
export class TinSpinnerInterceptor implements HttpInterceptor {
  constructor(private readonly spinnerService: TinSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isPaginationRequest = req.headers.get('page') === 'true'; // request running in background
    if (!isPaginationRequest) {
      const spinnerSubscription: Subscription = this.spinnerService.spinner$.subscribe();
      return next.handle(req).pipe(finalize(() => spinnerSubscription.unsubscribe()));
    } else {
      return next.handle(req);
    }
  }
}
