export const PermissionsGlobal = {
  Admin: ['Admin'],
}

export const PermissionsModuleClient = {
  ModuleClientLire: ['Module-Client_Lire', 'Admin'],
  ModuleClientEcrire: ['Module-Client_Ecrire', 'Admin'],

  //ClientTableauDeBordLire: ['Client-TableauDeBord_Lire', 'Admin'],
  ClientFactureLire: ['Client-Facture_Lire', 'Admin'],
  ClientInventaireLire: ['Client-Inventaire_Lire', 'Admin'],
  ClientPreferenceLire: ['Client-Preference_Lire', 'Admin'],
  ClientPreferenceEcrire: ['Client-Preference_Ecrire', 'Admin'],
  //ClientDemandeCueilletteEmailEcrire: ['Client-DemandeCueilletteEmail_Ecrire', 'Admin'],
  ClientMesClientsLire: ['Client-MesClients_Lire', 'Admin'],
  ClientMesClientsEcrire: ['Client-MesClients_Ecrire', 'Admin'],
  ClientMesClientsSupprime: ['Client-MesClients_Supprime', 'Admin'],
  ClientMesInformationsLire: ['Client-MesInformations_Lire', 'Admin'],
  ClientMesInformationsEcrire: ['Client-MesInformations_Ecrire', 'Admin'],
  ClientMesInformationsSupprime: ['Client-MesInformations_Supprime', 'Admin'],
  ClientDemandeCueilletteLire: ['Client-DemandeCueillette_Lire', 'Admin'],
  ClientDemandeCueilletteEcrire: ['Client-DemandeCueillette_Ecrire', 'Admin'],
  ClientDemandeCueilletteSupprime: ['Client-DemandeCueillette_Supprime', 'Admin'],
  ClientDemandeCueilletteFormulaire: ['Client-DemandeCueillette_Formulaire', 'Admin'],
};

export const PermissionsModuleEmploye = {
  ModuleEmployeLire: ['Module-Employe_Lire', 'Admin'],
  ModuleEmployeEcrire: ['Module-Employe_Ecrire', 'Admin'],

  EmployeTableauDeBordLire: ['Employe-TableauDeBord_Lire', 'Admin'],
  EmployeTalonPaieLire: ['Employe-TalonPaie_Lire', 'Admin'],
  EmployeHeuresTravailleesLire: ['Employe-HeuresTravaillees_Lire', 'Admin'],
  EmployeVacancesCongesSuivisLire: ['Employe-VacancesCongesFlottants_Lire', 'Admin'],
  EmployeJournalDeBordLire: ['Employe-JournalDeBord_Lire', 'Admin'],
  EmployeJournalDeComiteLire: ['Employe-JournalDeComite_Lire', 'Admin'],

  EmployeNotificationLire: ['Employe-Notification_Lire', 'Admin'],
  EmployeNotificationEcrire: ['Employe-Notification_Ecrire', 'Admin'],
  EmployeNotificationSupprime: ['Employe-Notification_Supprimer', 'Admin'],

  EmployeRepartitionLire: ['Employe-Repartition_Lire', 'Admin'],
  EmployeRepartitionEcrire: ['Employe-Repartition_Ecrire', 'Admin'],
  EmployeRepartitionSupprime: ['Employe-Repartition_Supprimer', 'Admin'],


  EmployeTerritoireLire: ['Employe-Territoire_Lire', 'Admin'],
  EmployeTerritoireEcrire: ['Employe-Territoire_Ecrire' , 'Admin'],
  EmployeTerritoireSupprimer: ['Employe-Territoire_Supprimer' , 'Admin'],

  EmployeInstallationsClientLire : ['Employe-InstallationsClient_Lire' ,'Admin'],
  EmployeInstallationsClientEcrire : ['Employe-InstallationsClient_Ecrire' ,'Admin'],
  EmployeInstallationsClientSupprimer : ['Employe-InstallationsClient_Supprimer' ,'Admin'],

  EmployeDemandeCueilletteLire: ['Employe-DemandeCueillette_Lire', 'Admin'],
  EmployeDemandeCueilletteEcrire: ['Employe-DemandeCueillette_Ecrire', 'Admin'],
  EmployeDemandeCueilletteSupprime: ['Employe-DemandeCueillette_Supprime', 'Admin'],

  EmployeServiceRequisLire: ['Employe-ServiceRequis_Lire','Admin'],
  EmployeServiceRequisEcrire: ['Employe-ServiceRequis_Ecrire','Admin'],
  EmployeServiceRequisSupprime: ['Employe-ServiceRequis_Supprime','Admin'],

  EmployeCeduleLire : ['Employe-Cedule_Lire', 'Admin'],
  EmployeSpeedDialLire : ['Employe-SpeedDial_Lire', 'Admin'],

  EmployeFormationLire : ['Employe-Formation_Lire', 'Admin'],
  EmployeFormationEcrire : ['Employe-Formation_Ecrire', 'Admin'],
  EmployeFormationSupprime : ['Employe-Formation_Supprime', 'Admin'],
  EmployeFormationVideoLire : ['Employe-FormationVideo_Lire','Admin'],

  EmployeGestionUserEmployeLire: ['Employe-GestionUserEmploye_Lire', 'Admin'],
  EmployeGestionUserEmployeEcrire: ['Employe-GestionUserEmploye_Ecrire', 'Admin'],
  EmployeGestionUserEmployeSupprime: ['Employe-GestionUserEmploye_Supprime', 'Admin'],

  EmployeGestionUserClientLire: ['Employe-GestionUserClient_Lire', 'Admin'],
  EmployeGestionUserClientEcrire: ['Employe-GestionUserClient_Ecrire', 'Admin'],
  EmployeGestionUserClientSupprime: ['Employe-GestionUserClient_Supprime', 'Admin']
};

export const PermissionsModuleAdmin = {
  ModuleAdminLire: ['Module-Admin_Lire', 'Admin'],
  ModuleAdminEcrire: ['Module-Admin_Ecrire', 'Admin'],
  ModuleAdminOuGestionUtilisateur: ['Module-Admin_Lire', 'Admin', 'Employe-GestionUserClient_Lire', 'Employe-GestionUserEmploye_Lire'],

  AdminGestionUtilisateursLire: ['Admin-GestionUtilisateurs_Lire', 'Admin'],
  AdminGestionUtilisateursEcrire: ['Admin-GestionUtilisateurs_Ecrire', 'Admin'],
  AdminGestionUtilisateursSupprimer: ['Admin-GestionUtilisateurs_Supprimer', 'Admin'],

  AdminGestionGroupesLire: ['Admin-GestionGroupes_Lire', 'Admin'],
  AdminGestionGroupesEcrire: ['Admin-GestionGroupes_Ecrire', 'Admin'],
  AdminGestionGroupesSupprimer: ['Admin-GestionGroupes_Supprimer', 'Admin'],

  AdminGestionProfileUtilisateur_Lire: ['Admin-GestionProfileUtilisateur_Lire','Admin'],
  AdminGestionProfileUtilisateur_Ecrire:['Admin-GestionProfileUtilisateur_Ecrire','Admin'],
  AdminGestionProfileUtilisateur_Supprimer:['Admin-GestionProfileUtilisateur_Supprimer','Admin']
};

export const PermissionsGroupClient = {
  //ClientTableauDeBord: PermissionsModuleClient.ClientTableauDeBordLire,
  ClientInventaire: PermissionsModuleClient.ClientInventaireLire,
  ClientFacture: PermissionsModuleClient.ClientFactureLire,
  ClientPreference: PermissionsModuleClient.ClientPreferenceLire
    .concat(PermissionsModuleClient.ClientPreferenceEcrire),
  //ClientDemandeCueilletteEmail: PermissionsModuleClient.ClientDemandeCueilletteEmailEcrire,
  ClientMesClient: PermissionsModuleClient.ClientMesClientsEcrire
    .concat(PermissionsModuleClient.ClientMesClientsLire)
    .concat(PermissionsModuleClient.ClientMesClientsSupprime),
 ClientMesInformations: PermissionsModuleClient.ClientMesInformationsEcrire
    .concat(PermissionsModuleClient.ClientMesInformationsLire)
    .concat(PermissionsModuleClient.ClientMesInformationsSupprime),
  ClientDemandeCueillette: PermissionsModuleClient.ClientDemandeCueilletteEcrire
    .concat(PermissionsModuleClient.ClientDemandeCueilletteLire)
    .concat(PermissionsModuleClient.ClientDemandeCueilletteSupprime),
    ClientDemandeCueilletteFormulaire: PermissionsModuleClient.ClientDemandeCueilletteFormulaire
};

export const PermissionsGroupEmploye = {
  EmployeTableauDeBord: PermissionsModuleEmploye.EmployeTableauDeBordLire,
  EmployeTalonPaie: PermissionsModuleEmploye.EmployeTalonPaieLire,
  EmployeHeuresTravaillees: PermissionsModuleEmploye.EmployeHeuresTravailleesLire,
  EmployeVacancesCongesSuivis: PermissionsModuleEmploye.EmployeVacancesCongesSuivisLire,
  EmployeJournalDeBord: PermissionsModuleEmploye.EmployeJournalDeBordLire,
  EmployeJournalDeComite: PermissionsModuleEmploye.EmployeJournalDeComiteLire,

  EmployeRepartition: PermissionsModuleEmploye.EmployeRepartitionLire
    .concat(PermissionsModuleEmploye.EmployeRepartitionEcrire)
    .concat(PermissionsModuleEmploye.EmployeRepartitionSupprime),
  

  EmployeTerritoire: PermissionsModuleEmploye.EmployeTerritoireEcrire
    .concat(PermissionsModuleEmploye.EmployeTerritoireLire)
    .concat(PermissionsModuleEmploye.EmployeTerritoireSupprimer),
  
  EmployeInstallationClient: PermissionsModuleEmploye.EmployeInstallationsClientEcrire
    .concat(PermissionsModuleEmploye.EmployeInstallationsClientSupprimer)
    .concat(PermissionsModuleEmploye.EmployeInstallationsClientLire),
    
  EmployeNotification: PermissionsModuleEmploye.EmployeNotificationLire
    .concat(PermissionsModuleEmploye.EmployeNotificationEcrire)
    .concat(PermissionsModuleEmploye.EmployeNotificationSupprime),

  EmployeDemandeCueillette: PermissionsModuleEmploye.EmployeDemandeCueilletteEcrire
    .concat(PermissionsModuleEmploye.EmployeDemandeCueilletteLire)
    .concat(PermissionsModuleEmploye.EmployeDemandeCueilletteSupprime),

  EmployeServiceRequis : PermissionsModuleEmploye.EmployeServiceRequisEcrire
    .concat(PermissionsModuleEmploye.EmployeServiceRequisLire)
    .concat(PermissionsModuleEmploye.EmployeServiceRequisSupprime),

  EmployeModule: PermissionsModuleEmploye.EmployeDemandeCueilletteEcrire.concat(PermissionsModuleEmploye.EmployeDemandeCueilletteLire).concat(PermissionsModuleEmploye.EmployeDemandeCueilletteSupprime)
    .concat(PermissionsModuleEmploye.EmployeRepartitionLire).concat(PermissionsModuleEmploye.EmployeRepartitionSupprime).concat(PermissionsModuleEmploye.EmployeRepartitionEcrire),

  EmployeAccesRapide : PermissionsModuleEmploye.EmployeCeduleLire.concat(PermissionsModuleEmploye.EmployeSpeedDialLire),

  EmployeFormationLire: PermissionsModuleEmploye.EmployeFormationVideoLire,
  EmployeGestionFormation : PermissionsModuleEmploye.EmployeFormationEcrire.concat(PermissionsModuleEmploye.EmployeFormationSupprime).concat(PermissionsModuleEmploye.EmployeFormationLire),

  EmployeGestionUsers: PermissionsModuleEmploye.EmployeGestionUserEmployeLire.concat(PermissionsModuleEmploye.EmployeGestionUserClientLire),
  EmployeGetionUserEmploye: PermissionsModuleEmploye.EmployeGestionUserEmployeEcrire
    .concat(PermissionsModuleEmploye.EmployeGestionUserEmployeLire)
    .concat(PermissionsModuleEmploye.EmployeGestionUserEmployeSupprime),

  EmployeGestionUserClient: PermissionsModuleEmploye.EmployeGestionUserClientEcrire
    .concat(PermissionsModuleEmploye.EmployeGestionUserClientLire)
    .concat(PermissionsModuleEmploye.EmployeGestionUserClientSupprime),
};
export const PermissionsGroupAdmin = {
  AdminGestionUtilisateurs: PermissionsModuleAdmin.AdminGestionUtilisateursLire
    .concat(PermissionsModuleAdmin.AdminGestionUtilisateursEcrire)
    .concat(PermissionsModuleAdmin.AdminGestionUtilisateursSupprimer),

  AdminGestionGroupes: PermissionsModuleAdmin.AdminGestionGroupesLire
    .concat(PermissionsModuleAdmin.AdminGestionGroupesEcrire)
    .concat(PermissionsModuleAdmin.AdminGestionGroupesSupprimer),

  AdminGestionProfileUtilisateur:PermissionsModuleAdmin.AdminGestionProfileUtilisateur_Lire
    .concat(PermissionsModuleAdmin.AdminGestionProfileUtilisateur_Ecrire)
    .concat(PermissionsModuleAdmin.AdminGestionProfileUtilisateur_Supprimer)
};

