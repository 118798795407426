import { Component, OnDestroy, OnInit } from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';
import { Subscription } from 'rxjs';
import { DynControlBase } from '../dyn-controls-base';

@Component({
  selector: 'app-dyn-editor',
  templateUrl: './dyn-editor.component.html',
  styleUrls: ['./dyn-editor.component.scss', '../dyn-controls.scss']
})
export class DynEditorComponent extends DynControlBase implements OnInit, OnDestroy {
  ngAfterContentChecked(): void {
    this.afterContentChecked()
  }
  editor!: Editor;
  html: string = '';
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  private  subscriptions = new Subscription()

  get readOnly(): boolean {
    const result = this.isReadOnly();
    result ? this.form.get(this.control?.keyName).disable() : this.form.get(this.control?.keyName).enable();
    return result;
  }

  ngOnInit(): void {
    this.editor = new Editor();
    this.subscriptions.add(this.editor.valueChanges.subscribe(x => {
      if (x && x['content']?.length > 0 && !x['content'][0].content) { // corrige le problème avec la valeur par défaut (</p>)
        const field = this.form.get(this.control?.keyName);
        setTimeout(() => {
          field.patchValue('');          
        }, 0);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.editor.destroy();
  }

}
