import { AfterContentChecked, Component } from '@angular/core';
import { DynControlBase } from '../dyn-controls-base';

@Component({
  selector: 'app-dyn-checkbox',
  templateUrl: './dyn-checkbox.component.html',
  styleUrls: ['./dyn-checkbox.component.scss', '../dyn-controls.scss']
})
export class DynCheckboxComponent extends DynControlBase implements AfterContentChecked{

  ngAfterContentChecked(): void {
    this.afterContentChecked();
  }
}
