<mat-card class="cardControl">
    <mat-card-content>
        <div [formGroup]="form">
            <mat-checkbox 
            [ngStyle]="control.style || false"
            (change)="callFunction()" 
            [disabled]="isReadOnly()"
            [formControlName]="control?.keyName">{{control.translatePrefixLabel + "." + getName() | translate}}
                <span *ngIf = "formGroup?.invalid" class="required">*</span> 
            </mat-checkbox>
        </div>
    </mat-card-content>
</mat-card>